<!-- eslint-disable linebreak-style -->
<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        style="width: 100px;"
        viewBox="0 0 83.916 37.127"
    >
        <g
            id="Trazado_668"
            transform="translate(-101.338 -220.373)"
            fill="#0088d6"
            stroke-miterlimit="10"
        >
            <path
                d="M 175.7173156738281 256.9998474121094 L 103.554557800293 256.9998474121094 C 102.6080474853516 256.9998474121094 101.8379974365234 256.2297973632812 101.8379974365234 255.2833251953125 L 101.8379974365234 222.5895080566406 C 101.8379974365234 221.6430053710938 102.6080474853516 220.8729705810547 103.554557800293 220.8729705810547 L 175.7173156738281 220.8729705810547 C 176.3930358886719 220.8729705810547 177.0080261230469 221.2715301513672 177.2840881347656 221.8883361816406 L 184.603759765625 238.2352905273438 C 184.8035125732422 238.6810760498047 184.8035125732422 239.1923065185547 184.6038208007812 239.6379852294922 L 177.2841186523438 255.9849700927734 C 177.0081787109375 256.6015014648438 176.3931884765625 256.9998474121094 175.7173156738281 256.9998474121094 Z"
                stroke="none"
            />
            <path
                d="M 175.7173156738281 256.4998474121094 C 176.1963500976562 256.4998474121094 176.6322021484375 256.2175903320312 176.8277893066406 255.7806396484375 L 184.1475219726562 239.4335174560547 C 184.2890014648438 239.1177673339844 184.2890014648438 238.755615234375 184.1474151611328 238.4396209716797 L 176.8277130126953 222.0925903320312 C 176.6320648193359 221.6554412841797 176.1961975097656 221.3729705810547 175.7173156738281 221.3729705810547 L 103.554557800293 221.3729705810547 C 102.8837432861328 221.3729705810547 102.3379974365234 221.918701171875 102.3379974365234 222.5895080566406 L 102.3379974365234 255.2833251953125 C 102.3379974365234 255.9541168212891 102.8837432861328 256.4998474121094 103.554557800293 256.4998474121094 L 175.7173156738281 256.4998474121094 M 175.7173156738281 257.4998474121094 L 103.554557800293 257.4998474121094 C 102.3304748535156 257.4998474121094 101.3379974365234 256.5073852539062 101.3379974365234 255.2833251953125 L 101.3379974365234 222.5895080566406 C 101.3379974365234 221.3654174804688 102.3304748535156 220.3729705810547 103.554557800293 220.3729705810547 L 175.7173156738281 220.3729705810547 C 176.5911712646484 220.3729705810547 177.3835754394531 220.8866882324219 177.7404632568359 221.6840667724609 L 185.0601043701172 238.0309600830078 C 185.3183288574219 238.6072540283203 185.3183288574219 239.2661285400391 185.0601043701172 239.8424224853516 L 177.7404632568359 256.1893005371094 C 177.3835754394531 256.9866943359375 176.5911712646484 257.4998474121094 175.7173156738281 257.4998474121094 Z"
                stroke="none"
                fill="#0088d6"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        height: {
            type: Number,
            default() {
                return 37.127;
            }
        },
        width: {
            type: Number,
            default() {
                return 83.916;
            }
        }
    }
};
</script>
