<template>
    <modal
        :adaptive="true"
        :click-to-close="false"
        name="person"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose()"
    >
        <div class="person-modal">
            <div class="row modal-header">
                <div class="col d-flex align-items-center">
                    <h5>Link a person</h5>
                    <a class="ml-auto" href="#" @click="$modal.hide('person')">x</a>
                </div>
            </div>
            <div class="row modal-body">
                <div class="col">
                    <div slot="container" class="row m-b-20">
                        <div class="col-12">
                            <resource-selector
                                v-model="person"
                                :resource="person"
                                :searchable-by="['name' , 'id']"
                                :show-add-resource="true"
                                :show-no-options="false"
                                :show-no-results="false"
                                name="person"
                                placeholder="Search for a person"
                                resource-id="id"
                                resource-label="person"
                                resource-url="/peoples"
                                @add-resource="addPerson"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row modal-footer">
                <div class="col">
                    <button class="btn btn-danger mr-2 btn-normal" @click="$modal.hide('person')">
                        Cancel
                    </button>
                </div>
                <div class="col">
                    <button :disabled="disableLinkButton" class="btn btn-primary btn-normal" @click="linkPerson()">
                        Link a person
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import ResourceSelector from "@c/resource-selector";

export default {
    name: "PersonModal",
    components: {
        ResourceSelector
    },
    data() {
        return {
            newPerson: "",
            person: null
        }
    },
    computed: {
        disableLinkButton() {
            return !this.person && this.newPerson === "";
        }
    },
    methods: {
        addPerson(name) {
            this.newPerson = name;
        },
        beforeOpen(event) {
            this.person = event.params.person;
        },
        beforeClose() {
            this.person = null;
        },
        createPerson() {
            if (this.newPerson === "") {
                return Promise.resolve();
            }

            return axios({
                url: "/peoples",
                method: "POST",
                data: {
                    name: this.newPerson
                }
            }).then(({ data }) => {
                this.person = data;
                this.newPerson = "";
            });
        },
        linkPerson() {
            this.createPerson().then(() => {
                this.$emit("select", this.person);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.person-modal {
    .modal-header {
        h5 {
            color: var(--base-color);
            margin: 0;
        }
    }

    .section-title {
        font-weight: 700;
    }

    .btn-success:disabled {
        cursor: not-allowed;
    }
}
</style>
