<template>
    <div class="tabs-control">
        <button
            style="border-radius: 3px; font-size: 14px"
            class="tabs-control-button"
            :class="{ 'tabs-control-button-selected': tab === 1 }"
            @click="tab = 1"
        >
            Feed
        </button>

        <button
            style="border-radius: 3px; font-size: 14px"
            class="tabs-control-button"
            :class="{ 'tabs-control-button-selected': tab === 2 }"
            @click="tab = 2"
        >
            Messages
        </button>
    </div>
</template>

<script>
export default {
    name: "TabsControl",
    props: {
        current: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            tab: 1
        };
    },
    watch: {
        tab() {
            this.$emit("change", this.tab);
        }
    }
};
</script>

<style lang="scss" scoped>
.tabs-control {
    background-color: #f7f7f7;
    border: 1px solid #ececec;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
    border-radius: 5px;

    .tabs-control-button {
        width: 50%;
        text-align: center;
        height: 35px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        border-radius: 3px;
        outline: none;
        background-color: transparent;
        color: #777777;
    }

    .tabs-control-button-selected {
        // color: #35d8f2;
        color: var(--base-color);
        background-color: #ffffff;
    }
}

button {
    border-radius: 3px;
    font-size: 14px;
}
</style>
