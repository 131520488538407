<template>
    <div class="contact-box">
        <div class="card">
            <div class="card-title">
                <slot name="title" />
            </div>

            <div class="card-title">
                <slot name="owner" />
            </div>

            <div class="card-body">
                <slot name="body" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeadBox"
};
</script>

<style lang="scss" scoped>
.contact-box {
    .card {
        padding: 0;

        .card-title {
            // padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            // border-bottom: 1px solid #e1e8ed;
            margin-bottom: 0;
            width: 100%;

            i {
                cursor: pointer;
            }
        }

        .card-body {
            padding: 10px;
        }
    }

    .people-form {
        .row {
            label {
                margin-bottom: 0;
            }

            .form-label {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                padding-right: 0;

                @media (max-width: 1600px) {
                    font-size: 12px;
                }
            }
        }

        .contact-delete {
            align-items: center;
            display: flex;
            padding: 0;

            i {
                cursor: pointer;
            }
        }

        &--footer {
            div:first-child {
                display: flex;
                justify-content: flex-end;
            }

            .btn {
                margin-left: 10px;
                position: static !important;
            }
        }
    }
}
</style>
