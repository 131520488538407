<template>
    <div class="browse-table" :class="{ deslindate: isDeslindate }">
        <modal
            :adaptive="true"
            :click-to-close="false"
            name="Create-Leads"
            height="auto"
        >
            <div class="user-modal">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Create Lead</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('Create-Leads')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row m-b-20">
                            <create-lead
                                :modal="true"
                                @cancel="$modal.hide('Create-Leads')"
                                @save="reload"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="Change-owner"
            height="auto"
            width="400"
        >
            <div class="">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Change owner</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('Change-owner')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row">
                            <Form
                                :modal="true"
                                :change-owner-mode="true"
                                :record-data="formData"
                                @saved="saveAndReload"
                                @cancel="$modal.hide('Change-owner')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="Change-stage"
            :height="'auto'"
            width="400"
        >
            <div class="">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Change stage</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('Change-stage')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row">
                            <Form
                                :modal="true"
                                :change-stage-mode="true"
                                :record-data="formData"
                                @saved="saveAndReload"
                                @cancel="$modal.hide('Change-stage')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="Change-status"
            height="200"
            width="300"
        >
            <div class="">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Change status</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('Change-status')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div
                            slot="container mx-auto"
                            class="row"
                            style="margin-left: 70px"
                        >
                            <button class="btn btn-won mr-2" @click="wonLead">
                                Won
                            </button>
                            <button
                                class="btn btn-lost"
                                @click="openConfirmLost"
                            >
                                Lost
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="Create-People"
            height="auto"
        >
            <div class="user-modal">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Create Person</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('Create-People')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row m-b-20">
                            <create-people :modal="true" />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="Create-Organizations"
            height="auto"
        >
            <div class="user-modal">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Create Organization</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('Create-Organizations')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row m-b-20">
                            <create-organization :modal="true" />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            :draggable="true"
            :adaptive="true"
            :scrollable="true"
            name="lost-lead-modal"
            height="auto"
        >
            <lost-lead-modal @saved="updateStatus" @reload="lostLead" />
        </modal>

        <gw-browse
            ref="gwBrowse"
            :append-params="appendParams"
            :http-options="{ baseURL, headers: { Authorization: token } }"
            :pagination-data="paginationData"
            :extra-fields="getFields(resource.slug || resource.name)"
            :query-params="queryParams"
            :resource="resource"
            :search-options="searchOptions"
            :search-placeholder="searchPlaceholder"
            :show-bulk-actions="false"
            :show-search-filters="false"
            pagination-path=""
            :app-search="useAppSearch"
            @load-error="loadError"
            @add="open"
        >
            <template v-slot:actions="props">
                <div
                    class="d-flex align-items-center justify-content-end"
                    style="height: fit-content"
                >
                    <el-popover
                        v-model="props.open"
                        placement="top"
                        width="160"
                    >
                        <div
                            style="
                                text-align: left;
                                margin: 0;
                                display: flex;
                                flex-direction: column;
                            "
                        >
                            <div style="cursor: pointer" class="my-1">
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="editResource(props.rowData)"
                                >
                                    <i class="fa fa-edit mr-2" />
                                    <span>Edit</span>
                                </el-button>
                            </div>

                            <div style="cursor: pointer" class="my-1">
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="openChangeStage(props.rowData.id)"
                                >
                                    <i class="fa-solid fa-arrow-right mr-2" />
                                    <span>Change Stage</span>
                                </el-button>
                            </div>

                            <div style="cursor: pointer" class="my-1">
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="openChangeStatus(props.rowData.id)"
                                >
                                    <i class="fab fa-stripe-s mr-2" />
                                    <span>Change Status</span>
                                </el-button>
                            </div>

                            <div style="cursor: pointer" class="my-1">
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="openChangeOwner(props.rowData.id)"
                                >
                                    <i class="fa-solid fa-user mr-2" />
                                    <span>Change owner</span>
                                </el-button>
                            </div>

                            <div style="cursor: pointer" class="my-1">
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="deleteResource(props.rowData.id)"
                                >
                                    <i class="fa fa-trash mr-2" />
                                    <span>Remove</span>
                                </el-button>
                            </div>
                        </div>
                        <el-button slot="reference" class="pointer">
                            <i
                                style="cursor: pointer"
                                class="fa-solid fa-ellipsis-vertical"
                            />
                        </el-button>
                    </el-popover>
                </div>
            </template>

            <template
                v-if="resource.slug == 'leads' && loadingText"
                v-slot:loading
            >
                <div class="loading py-4">
                    <img
                        src="https://mc-canvas.s3.amazonaws.com/progress-circle.svg"
                        width="48"
                        height="48"
                    />
                    <p class="m-0 mt-1 p-0">
                        {{ loadingText }}
                    </p>
                </div>
            </template>
        </gw-browse>
    </div>
</template>

<script>
const { GwBrowse } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);
import CONFIG from "@config/app-vars.js";
import { mapState } from "vuex";
import tableFields from "./tableFields.js";
import resourceParams from "./resourceParams.js";
import CreateLead from "../domains/gewaer/leads";
import CreateOrganization from "../domains/gewaer/organizations";
import CreatePeople from "../domains/gewaer/peoples";
import Form from "../domains/gewaer/leads/form.vue";
import { setTimeout } from "timers";

export default {
    name: "Browse",
    components: {
        GwBrowse,
        CreateLead,
        CreateOrganization,
        CreatePeople,
        Form,
        LostLeadModal: () =>
            import(
                /* webpackChunkName: "lost-lead-modal"*/ "../domains/gewaer/leads/lost-lead-modal.vue"
            ),
    },
    data() {
        return {
            CONFIG,
            isExpanded: false,
            baseURL: process.env.VUE_APP_BASE_API_URL,
            queryParams: {
                sort: "sort",
                page: "page",
                perPage: "limit",
            },
            token: this.$store.state.User.token || Cookies.get("token"),
            loadingText: "",
            formData: {},
        };
    },
    computed: {
        ...mapState({
            resources: (state) => state.Application.resources,
        }),
        appendParams() {
            const appendParams = {
                format: "true",
            };
            const resource = this.$route.params.resource;

            if (resource in resourceParams) {
                for (const [key, value] of Object.entries(
                    resourceParams[resource].appendParams
                )) {
                    appendParams[key] = value;
                }
            }

            return appendParams;
        },
        useAppSearch() {
            // @todo: this should be refactored with an option in the resource entity that has app-search
            const resourcesSlugsWithAppSearch = ["leads", "peoples"];
            return resourcesSlugsWithAppSearch.includes(this.resource.slug);
        },
        resource() {
            const resources = [];
            this.resources.forEach((link) => {
                if (link.links) {
                    resources.push(link, ...link.links);
                } else {
                    resources.push(link);
                }
            });
            return resources.find(
                (resource) => resource.slug == this.$route.params.resource
            );
        },
        isDeslindate() {
            return CONFIG.domain == "deslindate";
        },
        searchOptions() {
            const resource = this.$route.params.resource;

            if (resource in resourceParams) {
                return resourceParams[resource].searchOptions || {};
            }

            return {};
        },
        searchPlaceholder() {
            const maps = {
                leads: "open leads",
            };
            const resource = this.resource.title.toLowerCase();

            return `Search ${maps[resource] || resource}...`;
        },
    },
    mounted() {
        window.$reloadTable = this.reload;
        window.$browseTable = this;
    },
    methods: {
        openChangeOwner(id) {
            this.$modal.show("Change-owner");
            this.loadLead(id);
        },
        openChangeStage(id) {
            this.$modal.show("Change-stage");
            this.loadLead(id);
        },
        openChangeStatus(id) {
            this.$modal.show("Change-status");
            this.loadLead(id);
        },
        loadLead(id) {
            axios({
                url: `leads/${id}`,
            })
                .then(async ({ data }) => {
                    this.formData = data;
                })
                .catch((error) => {
                    this.$notify({
                        title: "Error",
                        text: error.response
                            ? error.response.data.errors.message
                            : "Error",
                        type: "error",
                    });
                });
        },
        saveAndReload() {
            this.$modal.hide("Change-owner");
            this.reload();
        },
        open(event) {
            this.$modal.show(`Create-${event}`);
        },
        getFields: tableFields.getFields,
        loadError(error) {
            this.$notify({
                title: "Error",
                text: error.response.data.errors.message,
                type: "error",
            });
        },
        paginationData(data) {
            const paginationData = {
                total: parseInt(data.total_rows),
                per_page: parseInt(data.limit),
                current_page: parseInt(data.page),
                last_page: parseInt(data.total_pages),
            };

            const nextParams = this.$refs.gwBrowse.getAllQueryParams();
            nextParams.page =
                nextParams.page == paginationData.last_page
                    ? null
                    : nextParams.page + 1;
            const prevParams = this.$refs.gwBrowse.getAllQueryParams();
            prevParams.page = prevParams.page == 1 ? null : prevParams.page - 1;

            const nextQuery = Object.keys(nextParams).map(
                (key) => `${key}=${nextParams[key]}`
            );
            const prevQuery = Object.keys(prevParams).map(
                (key) => `${key}=${prevParams[key]}`
            );

            paginationData.next_page_url =
                nextParams.page === null
                    ? null
                    : `${this.baseURL}?${nextQuery.join("&")}&format=true`;
            paginationData.prev_page_url =
                prevParams.page === null
                    ? null
                    : `${this.baseURL}?${prevQuery.join("&")}&format=true`;
            paginationData.from =
                (paginationData.current_page - 1) * paginationData.per_page + 1;
            paginationData.to =
                paginationData.from + paginationData.per_page - 1;

            return paginationData;
        },
        createResource() {
            if (
                this.resource.create_template &&
                this.resource.create_template.includes("modal")
            ) {
                this.$modal.show(this.resource.create_template, {
                    resource: this.resource,
                });
            } else {
                this.$router.push({
                    name: "create-resource",
                    params: {
                        resource: this.resource.slug,
                    },
                });
            }
        },
        editResource(record) {
            this.$router.push({
                name: "edit-resource",
                params: {
                    id: record.id,
                    resource: this.resource.route || this.resource.slug,
                },
            });
        },
        async reload() {
            await this.$refs.gwBrowse.$refs.Vuetable.reload();
            this.loadingText = "";
        },
        // Add this delay to add more time between reloading the data
        // due to elastic taking some time to return deleted records
        delayLoad(text) {
            this.loadingText = text;
            this.$refs.gwBrowse.loading = true;
            const delay = (fn) => {
                setTimeout(() => {
                    fn();
                }, 2000);
            };
            return delay;
        },
        deleteResource(id) {
            if (confirm("Are you sure you want to delete this record?")) {
                const url = this.resource.endpoint || this.resource.slug;
                const delay = this.delayLoad(
                    "Deleting the lead and it's resources"
                );
                axios({
                    method: "DELETE",
                    url: `${url}/${id}`,
                }).then(() => {
                    if (this.resource.slug == "leads") {
                        delay(this.reload);
                    } else {
                        this.reload();
                    }
                });
            }
        },
        wonLead() {
            this.updateStatus(
                {
                    leads_status_id: 6,
                    title: this.formData.title.trim(),
                },
                () => {
                    this.$modal.hide("Change-status");
                    this.reload();
                }
            );
        },
        lostLead() {
            setTimeout(this.reload, 2000);
        },
        updateLead(formData) {
            return axios({
                url: `leads/${this.formData.id}`,
                data: formData,
                method: "PUT",
            });
        },
        updateStatus(formData, cb = () => {}) {
            const statusMessage = [
                "Status updated",
                "Congrats lead won!",
                "The lead has been lost",
            ];

            this.isLoading = true;
            this.updateLead({
                ...formData,
                title: this.formData.title.trim(),
            })
                .then(({ data }) => {
                    this.formData.status = data.status;
                    this.$notify({
                        title: "Success",
                        text: statusMessage[formData.status],
                        type: "successs",
                    });
                    this.$root.$emit("leads:status-updated", data);
                    setTimeout(cb, 1000);
                })
                .catch((error) => {
                    this.$notify({
                        title: "Error",
                        text: error.response
                            ? error.response.data.errors.message
                            : "Error",
                        type: "error",
                    });
                    return false;
                });
        },
        openConfirmLost() {
            this.$modal.show("lost-lead-modal");
            this.$modal.hide("Change-status");
        },
    },
};
</script>

<style lang="scss">
.browse-table {
    position: relative;
    .table-container {
        border: 0;
        box-shadow: 0 7px 14px rgba(132, 132, 132, 0.15);
        padding: 20px;
        border-radius: 5px;
        background-color: white;
    }
}

.deslindate {
    .table-actions,
    .browse-actions,
    .pagination-controls,
    .vuetable-th-component-status-date,
    .vuetable-td-component-status-date,
    .vuetable-th-phone,
    .vuetable-td-phone {
        display: none !important;
    }
}

.btn-won {
    background: #ebf8e9;
    color: #6eca64;
}

.btn-lost {
    background: #ffeced;
    color: #ff7b80;
}
</style>
