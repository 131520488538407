<template>
    <pipeline-start-active v-if="active" :height="height" :width="width" />
    <pipeline-start-default v-else-if="pass" :height="height" :width="width" />
    <pipeline-start-inactive v-else :height="height" :width="width" />
</template>

<script>
import PipelineStartActive from "./pipeline-start-active.vue";
import PipelineStartInactive from "./pipeline-start-inactive.vue";
import PipelineStartDefault from "./pipeline-start-default.vue";


export default {
    name: "PipelineStart",
    components: {
        PipelineStartActive,
        PipelineStartInactive,
        PipelineStartDefault
    },
    props: {
        active: {
            type: Boolean,
            default: false
        },
        pass: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default() {
                return 37.127;
            }
        },
        width: {
            type: Number,
            default() {
                return 75.2;
            }
        }
    }
};
</script>
