<template>
    <div
        class="container-fixed-lg leads"
        :class="{ 'is-deleting': isDeleting }"
    >
        <div v-if="!isEditing || editMode" class="row">
            <div class="col-md-12">
                <leads-form
                    :edit-mode="editMode"
                    :record-data="formData"
                    :status-list="leadStatus"
                    :modal="modal"
                    @saved="onSave"
                    @cancel="onCancel"
                />
            </div>
        </div>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="edit-lead-box"
            height="auto"
        >
            <div class="user-modal">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Edit lead</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('edit-lead-box')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row m-b-20">
                            <leads-form
                                :edit-mode="editMode"
                                :record-data="formData"
                                :status-list="leadStatus"
                                :modal="'true'"
                                @saved="onSave"
                                @cancel="onCancel"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="Change-owner-inside"
            height="auto"
            width="400"
        >
            <div class="user-modal">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Change owner</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('Change-owner-inside')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row m-b-20">
                            <LeadsForm
                                :modal="true"
                                :change-owner-mode="true"
                                :record-data="formData"
                                @saved="saveAndReload(formData.id)"
                                @cancel="$modal.hide('Change-owner-inside')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <div v-if="isEditing" class="row">
            <div class="col-5">
                <div class="cards-column">
                    <lead-box>
                        <template #title>
                            <h1
                                class="section-title p-l-10"
                                style="
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    width: 100%;
                                "
                            >
                                <div
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                    "
                                >
                                    <div>
                                        <i
                                            aria-hidden="true"
                                            class="fas fa-user mr-2"
                                        />
                                        <span>
                                            {{ formData.title }}
                                        </span>

                                        <button
                                            v-if="!editMode"
                                            class="pl-2 pr-2"
                                            style="
                                                all: unset;
                                                font-size: 16px;
                                                cursor: pointer;
                                            "
                                            @click.prevent="
                                                () => {
                                                    $modal.show(
                                                        'edit-lead-box'
                                                    );
                                                }
                                            "
                                        >
                                            <i class="fa fa-pencil" />
                                        </button>
                                    </div>

                                    <strong
                                        style="
                                            margin-top: 10px;
                                            color: #383752;
                                            font-size: 10px;
                                        "
                                    >
                                        <!-- <i class="far fa-calendar-alt" /> -->
                                        {{ startDate }}
                                    </strong>
                                </div>
                                <dropdown
                                    ref="options"
                                    :is-icon="false"
                                    :x="-117"
                                    class="lead-options"
                                    :close-on-click="true"
                                >
                                    <template slot="btn">
                                        <i
                                            class="fa-solid fa-ellipsis-vertical"
                                        />
                                    </template>
                                    <div slot="body">
                                        <div
                                            class="option-row"
                                            @click="deleteLead()"
                                        >
                                            Delete
                                        </div>
                                    </div>
                                </dropdown>
                            </h1>
                        </template>

                        <template #owner>
                            <div
                                style="
                                    justify-self: flex-start;
                                    align-self: flex-start;
                                    width: 100%;
                                    display: flex;
                                    justify-items: center;
                                    align-items: center;
                                    justify-content: space-between;
                                "
                            >
                                <div
                                    style="
                                        display: flex;
                                        justify-items: center;
                                        align-items: center;
                                    "
                                >
                                    <div class="mr-2">
                                        <img
                                            v-if="formData.owner"
                                            :src="formData.owner.photo.url"
                                            :alt="formData.owner.full_name"
                                            style="
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                            "
                                        />
                                    </div>
                                    <div
                                        style="
                                            display: flex;
                                            flex-direction: column;
                                        "
                                    >
                                        <span v-if="formData.owner">
                                            {{ formData.owner.full_name }}
                                            <i
                                                class="fa-solid fa-angle-down"
                                                @click="
                                                    $modal.show(
                                                        'Change-owner-inside'
                                                    )
                                                "
                                            />
                                        </span>
                                        <span
                                            style="
                                                font-size: 12px;
                                                color: #707070;
                                            "
                                        >
                                            Owner
                                        </span>
                                    </div>
                                </div>

                                <div
                                    v-show="Object.keys(formData).length > 0"
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: start;
                                        align-items: end;
                                    "
                                >
                                    <div
                                        v-if="
                                            featureFlags.leads_won_lost_actions &&
                                            isOpen
                                        "
                                        style="
                                            display: flex;
                                            margin-bottom: 10px;
                                            justify-items: center;
                                            align-items: center;
                                        "
                                    >
                                        <span
                                            style="
                                                color: #383752;
                                                margin-right: 10px;
                                                font-size: 12px;
                                            "
                                        >
                                            Mark as:
                                        </span>
                                        <button
                                            class="btn btn-won mr-2"
                                            :disabled="isLoading"
                                            @click="
                                                updateStatus({
                                                    leads_status_id: STATUS_WON,
                                                    title: formData.title.trim(),
                                                })
                                            "
                                        >
                                            Won
                                        </button>
                                        <button
                                            class="btn btn-lost"
                                            :disabled="isLoading"
                                            @click="openConfirmLost()"
                                        >
                                            Lost
                                        </button>
                                    </div>

                                    <div style="display: flex">
                                        <template v-if="!isOpen">
                                            <span
                                                class="badge badge-success d-flex justify-content-center align-items-center p-2"
                                            >
                                                {{ statusText }}
                                            </span>
                                        </template>

                                        <div
                                            style="
                                                display: flex;
                                                flex-direction: row;
                                                justify-content: space-between;
                                            "
                                        >
                                            <button
                                                v-if="
                                                    !isOpen &&
                                                    featureFlags.leads_won_lost_actions
                                                "
                                                class="ml-3 btn btn-primary"
                                                :disabled="isLoading"
                                                @click="
                                                    updateStatus({
                                                        leads_status_id: STATUS_OPEN,
                                                        title: formData.title.trim(),
                                                    })
                                                "
                                            >
                                                Re-Open Lead
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template #body>
                            <div v-if="isEditing" class="mb-2 row">
                                <div class="col">
                                    <div class="client-name">
                                        <skeleton
                                            v-if="!formData.title"
                                            height="30px"
                                            width="400px"
                                        />
                                        <span
                                            v-if="
                                                featureFlags.leads_pipeline_stages &&
                                                pipeline &&
                                                stage
                                            "
                                        >
                                            {{ pipeline.name }}
                                            <i class="fas fa-angle-right" />
                                            {{ stage.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <template
                                v-if="
                                    featureFlags.leads_pipeline_stages &&
                                    isEditing
                                "
                            >
                                <stages-box
                                    :current-stage="stage"
                                    :stages="stages"
                                    @selected="setStage"
                                />
                            </template>
                        </template>
                    </lead-box>

                    <contact-box
                        :peoples-id="formData.people_id"
                        @updated="hasMessageInstance"
                    />

                    <organization-box
                        :organization-id="formData.organization_id"
                    />

                    <lead-info-box
                        :data="formData.custom_fields"
                        :form="formData"
                    />

                    <template v-if="featureFlags.leads_managers">
                        <members-box
                            ref="MembersBox"
                            v-model="followers"
                            :lead-id="formData.id"
                        />
                    </template>
                </div>
            </div>
            <div class="col activities-column">
                <deslindate-request-info
                    v-if="isDeslindate"
                    :request-info="formData"
                />

                <Tabs v-if="showTabs" @change="changeTab" />

                <div v-show="tab === 1">
                    <activities-box
                        :form-data="formData"
                        :show-chat="featureFlags.leads_chat"
                        @message-saved="addMessage"
                        @activity-saved="addActivity"
                    />
                    <completed-activities
                        :lead="formData"
                        :complete="complete"
                        :activities="messages"
                        :activities-pagination="messagesPagination"
                        @get-activities-next-page="getMessagesNextPage"
                        @deleted="removeMessageById"
                        @updated="addActivity"
                    />
                </div>

                <div v-show="tab === 2">
                    <Messages
                        v-if="showMessages"
                        :person="personData"
                        :identity="identity"
                    />
                </div>
            </div>
        </div>
        <modal
            :draggable="true"
            :adaptive="true"
            :scrollable="true"
            name="lost-lead-modal"
            height="auto"
        >
            <lost-lead-modal @saved="updateStatus" />
        </modal>
        <organization-modal @select="linkOrganization" />
        <person-modal @select="linkPerson" />
        <user-modal :disabled-users="disabledUsers" @select="addFollower" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CONFIG from "@config/app-vars";

import { Skeleton } from "vue-loading-skeleton";
import moment from "moment";
import LeadsForm from "./form";
import ContactBox from "./contact-box";
import LeadBox from "./lead-box";
import OrganizationBox from "./organization-box";
import LeadInfoBox from "./lead-info-box.vue";
import MembersBox from "./members-box";
import StagesBox from "./stages-box";
import ActivitiesBox from "./activities-box";
import PersonModal from "@c/modals/person";
import UserModal from "@c/modals/user";
import OrganizationModal from "@c/modals/organization";
import DeslindateRequestInfo from "@v/domains/deslindate/admin/request-Info";
// import LeadStopwatch from "./stopwatch";
// import LeadStopwatchSkeleton from "./stopwatch-skeleton.vue";
import Messages from "@c/messages";
import Tabs from "./Tabs.vue";

export default {
    name: "Leads",
    components: {
        LostLeadModal: () =>
            import(
                /* webpackChunkName: "lost-lead-modal"*/ "./lost-lead-modal"
            ),
        CompletedActivities: () =>
            import(
                /* webpackChunkName: "completed-activities"*/ "@c/feed/completed-activities"
            ),
        // associationBox: () => import(/* webpackChunkName: "association-box"*/ "./association-box"),
        ContactBox,
        OrganizationBox,
        MembersBox,
        StagesBox,
        ActivitiesBox,
        LeadsForm,
        Skeleton,
        PersonModal,
        UserModal,
        OrganizationModal,
        DeslindateRequestInfo,
        // LeadStopwatch,
        // LeadStopwatchSkeleton,
        Tabs,
        Messages,
        LeadBox,
        LeadInfoBox,
    },
    props: {
        isEditing: {
            type: Boolean,
        },
        modal: {
            type: Boolean,
            deafult: false,
        },
    },
    data() {
        return {
            endpoint: "leads",
            editMode: false,
            leadStatus: [],
            isLoading: false,
            STATUS_PENDING: 1,
            STATUS_WON: 6,
            STATUS_LOST: 5,
            STATUS_OPEN: 2,
            formData: {},
            person: null,
            messages: [],
            messagesPagination: {
                currentPage: 1,
                limit: 5,
                totalPages: 0,
                isLoading: true,
            },
            chatMembers: [],
            pipeline: null,
            plannedActivities: [
                {
                    id: 1,
                    type: "call",
                    icon: "fas fa-phone",
                    title: "Get approval",
                    dueTime: "12 hours",
                    contact: "Johnito Doesis",
                },
            ],
            stage: null,
            stages: [],
            followers: [],
            isDeleting: false,
            tab: 1,
            personData: {},
            showTabs: false,
            showMessages: false,
            identity: null,
            complete: false,
        };
    },
    computed: {
        ...mapGetters({
            featureFlags: "Application/featureFlags",
        }),
        creationMode() {
            return this.isEditing || this.editMode;
        },
        isOpen() {
            return this.formData.leads_status.name === "Active";
        },
        isWon() {
            return this.formData.leads_status.name === "Complete";
        },
        startDate() {
            return moment(this.formData.created_at).format("MMMM DD, YYYY");
        },
        statusText() {
            return this.isWon ? "Won" : "Lost";
        },
        isDeslindate() {
            return CONFIG.domain == "deslindate";
        },
        disabledUsers() {
            return this.followers.map((member) => member.users);
        },
    },
    watch: {
        "$route.params.id": {
            handler(id) {
                if (id) {
                    return this.getRecord(id);
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.getStatus();
        this.getEngagementTypes();
        this.getActions();
    },
    methods: {
        saveAndReload(id) {
            this.$modal.hide("Change-owner-inside");
            this.getRecord(id);
        },
        changeTab(tab) {
            this.tab = tab;
        },
        ...mapActions({
            getEngagementTypes: "Application/getEngagementTypes",
            getActions: "Application/getActions",
        }),
        deleteLead() {
            const confirmDelete = confirm(
                "Are you sure you want to delete this lead?"
            );

            if (confirmDelete) {
                this.isDeleting = true;
                axios({
                    url: `/leads/${this.formData.id}`,
                    method: "DELETE",
                }).then(() => {
                    // Add this delay to add more time between returning
                    // the user to the leads page due to elastic taking
                    // some time to return deleted records
                    setTimeout(() => {
                        this.isDeleting = false;
                        this.$router.push({
                            name: "browse",
                            params: {
                                resource: "leads",
                            },
                        });
                    }, 2000);
                });
            }
        },
        fetchPipeline() {
            axios({
                url: `/pipelines/${this.formData.pipeline_id}`,
            }).then(({ data }) => {
                this.pipeline = data;
            });
        },
        fetchPipelineStages() {
            axios({
                url: `/pipelines/${this.formData.pipeline_id}/stages`,
            }).then(({ data }) => {
                this.stages = data;
                this.stage = data.find(
                    (stage) => stage.id == this.formData.pipeline_stage_id
                );
            });
        },
        linkOrganization(organization) {
            this.updateLead({
                organization_id: organization.id,
                title: this.formData.title.trim(),
            }).then(() => {
                this.formData.organization_id = Number(organization.id);
                this.$modal.hide("organization");
            });
        },
        linkPerson(person) {
            this.updateLead({ people_id: person.id }).then(() => {
                this.formData.people_id = Number(person.id);
                this.$modal.hide("person");
            });
        },
        addFollower(user) {
            axios({
                url: `/leads/${this.formData.id}/access`,
                method: "POST",
                data: {
                    users_id: [user.id],
                },
            })
                .then(({ data }) => {
                    this.followers.push(data[0]);
                    this.$modal.hide("user");
                })
                .catch(() => {
                    this.onFollowerDuplicated();
                });
        },
        onFollowerDuplicated() {
            this.$notify({
                title: "Info",
                message: "User already has access to this chat",
                type: "info",
            });
        },
        onSave(record) {
            if (!this.isEditing) {
                this.$router.push({
                    name: "edit-resource",
                    params: {
                        resource: "leads",
                        id: record.id,
                    },
                });
            } else {
                this.getRecord(record.id, false);
                this.editMode = false;
                this.$modal.hide("edit-lead-box");
            }
        },
        onCancel() {
            if (!this.isEditing) {
                this.$modal.hide("Create-Leads");
            } else {
                this.editMode = false;
                this.$modal.hide("edit-lead-box");
            }
        },
        getStatus() {
            axios({
                url: `leads-status`,
            }).then(({ data }) => {
                if (data) {
                    this.leadStatus = data;
                }
            });
        },
        getRecord(id, shouldGetMessages = true) {
            axios({
                url: `${this.endpoint}/${id}`,
            })
                .then(async ({ data }) => {
                    if (data) {
                        const formData = { ...data };

                        formData.source_value =
                            formData.leadSource &&
                            formData.leadSource.source_leads_id_text;
                        this.formData = { ...formData };
                        this.fetchPipeline();
                        this.fetchPipelineStages();

                        this.getPerson().then(() => {
                            this.hasMessageInstance();
                        });

                        if (shouldGetMessages) {
                            this.getMessages({
                                currentPage: this.messagesPagination
                                    .currentPage,
                                limit: this.messagesPagination.limit,
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.$notify({
                        title: "Error",
                        text: error.response
                            ? error.response.data.errors.message
                            : "Error",
                        type: "error",
                    });

                    this.$router.push({
                        name: "browse",
                        params: {
                            resource: "leads",
                        },
                    });
                });
        },
        getMessages({ currentPage, limit }) {
            this.messagesPagination.isLoading = true;

            axios({
                url: `/messages`,
                params: {
                    q: `(chs.slug:${this.formData.uuid},parent_id:0)`,
                    format: true,
                    page: currentPage,
                    sort: "created_at|DESC",
                    limit,
                },
            })
                .then(({ data: { data, total_pages: totalPages } }) => {
                    if (data) {
                        this.messages = [...this.messages, ...data];
                        this.messagesPagination.totalPages = totalPages;
                    }

                    this.messagesPagination.isLoading = false;
                })
                .catch((error) => {
                    this.messagesPagination.isLoading = false;

                    this.$notify({
                        title: "Error",
                        text: error.response
                            ? error.response.data.errors.message
                            : "Error",
                        type: "error",
                    });
                });
        },
        async getMessagesNextPage() {
            if (
                this.messagesPagination.currentPage <=
                this.messagesPagination.limit
            ) {
                this.messagesPagination.currentPage += 1;
                await this.getMessages({
                    currentPage: this.messagesPagination.currentPage,
                    limit: this.messagesPagination.limit,
                });
            } else {
                this.complete = true;
            }
        },
        addMessage(message) {
            this.messages.unshift(message);
        },
        addActivity() {
            // idk wtf
            setTimeout(() => {
                axios({
                    url: `/messages`,
                    params: {
                        q: `(chs.slug:${this.formData.uuid},parent_id:0)`,
                        format: true,
                        sort: "updated_at|DESC",
                    },
                })
                    .then(({ data: { data } }) => {
                        if (data) {
                            this.messages = [];
                            this.messages = [...data];
                        }
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response
                                ? error.response.data.errors.message
                                : "Error",
                            type: "error",
                        });
                    });
            }, 1000);
        },
        removeMessageById(id) {
            this.messages = this.messages.filter(
                (message) => message.id !== id
            );
        },
        openConfirmLost() {
            this.$modal.show("lost-lead-modal");
        },
        updateLead(formData) {
            return axios({
                url: `${this.endpoint}/${this.formData.id}`,
                data: formData,
                method: "PUT",
            });
        },
        setStage(stage) {
            this.updateLead({
                pipeline_stage_id: stage.id,
                title: this.formData.title.trim(),
            }).then(() => {
                this.formData.pipeline_stage_id = Number(stage.id);
                this.stage = this.stages.find((item) => item.id == stage.id);
            });
        },
        updateStatus(formData) {
            const statusMessage = [
                "Status updated",
                "Congrats lead won!",
                "The lead has been lost",
            ];

            if (!this.isLoading) {
                this.isLoading = true;
                this.updateLead({
                    ...formData,
                    title: this.formData.title.trim(),
                })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.formData.status = data.status;
                        this.$notify({
                            title: "Success",
                            text: statusMessage[formData.status],
                            type: "successs",
                        });
                        this.$root.$emit("leads:status-updated", data);
                        this.getRecord(data.id, false);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response
                                ? error.response.data.errors.message
                                : "Error",
                            type: "error",
                        });
                        return false;
                    })
                    .finally((result) => {
                        this.isLoading = false;
                        return result;
                    });
            }
        },
        onStopwatchStart(data) {
            this.updateLeadStopwatch(data);
        },
        onStopwatchStop(data) {
            this.updateLeadStopwatch(data);
            this.formData.leads_visits_count = data.leads_visits_count;
        },
        updateLeadStopwatch(data) {
            this.formData.chrono_start_date = data.chrono_start_date;
            this.formData.is_chrono_running = data.is_chrono_running;
        },
        async getPerson() {
            if (this.formData.people_id) {
                const { data } = await axios({
                    url: `peoples/${this.formData.people_id}`,
                    method: "GET",
                });

                this.personData = data;
            }
        },
        async hasMessageInstance() {
            await this.getPerson().then(async () => {
                if (Object.keys(this.personData).length > 0) {
                    const phone = this?.personData?.phone
                        ?.trim()
                        ?.replaceAll(/\D/g, "");
                    const hasPhone = this?.personData?.phone?.trim() !== "";
                    const id = this.$route?.params?.id;

                    const friendlyName = `Chat-lead-${phone}`;
                    const identity = `Chat-${phone}`;

                    this.showTabs = hasPhone;

                    if (hasPhone) {
                        // get data from /lead/{id}/chat
                        const data = await this.getTwilioInfo(id);

                        if (data === null) {
                            // create twilio instance
                            this.createTwilioMessaging(
                                friendlyName,
                                `+1${phone}`,
                                identity
                            ).then(({ csid }) => {
                                // set data in /lead/{id}/chat
                                this.setTwilioInfo(id, identity, csid).then(
                                    () => {
                                        // request twilio token
                                        this.getTwilioToken(
                                            identity,
                                            csid
                                        ).then(({ token }) => {
                                            // store token
                                            this.twilioToken(token);
                                            this.identity = identity;

                                            this.showTabs = hasPhone;
                                            this.showMessages = true;

                                            console.log("here");
                                        });
                                    }
                                );
                            });
                        } else {
                            const { identity, services_id: servicesId } = data;
                            // request twilio token
                            const { token } = await this.getTwilioToken(
                                identity,
                                servicesId
                            );

                            // store token
                            this.twilioToken(token);
                            this.identity = identity;
                            this.showTabs = hasPhone;
                            this.showMessages = true;
                        }
                    }
                }
            });
        },
        async setTwilioInfo(id, identity, servicesId) {
            try {
                const { data } = await axios({
                    url: `/leads/${id}/chat`,
                    method: "POST",
                    data: {
                        identity,
                        services_id: servicesId,
                    },
                });

                return data;
            } catch (error) {
                if (error.message === "Request failed with status code 404") {
                    return null;
                }
                return error;
            }
        },
        async getTwilioInfo(id) {
            try {
                const { data } = await axios({
                    url: `/leads/${id}/chat`,
                    method: "GET",
                });

                return data;
            } catch (error) {
                if (error.message === "Request failed with status code 404") {
                    return null;
                }
                return error;
            }
        },
        async getTwilioToken(identity, servicesid) {
            try {
                const params = new URLSearchParams();

                params.append("identity", identity);
                params.append("servicesid", servicesid);

                const url = `${
                    process.env.VUE_APP_GENERATE_TWILIO_TOKEN
                }?${params.toString()}`;

                const response = await fetch(url);
                const data = await response.json();

                return data;
            } catch (error) {
                console.log(error);
            }
        },
        async createTwilioMessaging(friendlyName, phoneNumber, identity) {
            const params = new URLSearchParams();

            params.append("friendlyName", friendlyName);
            params.append("phoneNumber", phoneNumber);
            params.append("identity", identity);

            console.log({ friendlyName, phoneNumber, identity });

            const response = await fetch(
                `${
                    process.env.VUE_APP_CREATE_TWILIO_INSTACE
                }?${params.toString()}`,
                {
                    method: "POST",
                }
            );

            const data = await response.json();

            return data;
        },
        twilioToken(setData) {
            if (setData) {
                return window.localStorage.setItem(
                    "twilio.token.chat",
                    setData
                );
            }

            return window.localStorage.getItem("twilio.token.chat");
        },
    },
};
</script>

<style lang="scss">
.tabs-control {
    background-color: #f7f7f7;
    border: 1px solid #ececec;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
    border-radius: 5px;

    .tabs-control-button {
        width: 50%;
        text-align: center;
        height: 35px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        border-radius: 3px;
        outline: none;
        background-color: transparent;
        color: #777777;
    }

    .tabs-control-button-selected {
        color: #35d8f2;
        background-color: #ffffff;
    }
}

.messages {
    flex-shrink: 2;
    overflow-y: auto;

    .time {
        font-size: 0.8rem;
        background: #e8e6e6;
        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        color: rgba(black, 0.5);
        width: fit-content;
        margin: 0 auto;
    }

    .message {
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        margin: 1rem;
        background: #ffffff;
        border-radius: 0.5rem;
        min-height: fit-content;
        width: fit-content;
        max-width: 40%;
        word-break: keep-all;
        box-shadow: 0 0 2rem 0 rgba(black, 0.1),
            0rem 2rem 4rem -3rem rgba(black, 0.5);

        &.parker {
            margin: 1rem 1rem 1rem auto;
            background: #eeeeee;
            color: black;
            box-shadow: none;
        }
    }
}

.leads {
    button:not(.btn-normal) {
        padding: 4px 15px;
        border-radius: 10px;
        font-size: 11px;
    }

    .btn-won {
        background: #ebf8e9;
        color: #6eca64;
    }

    .btn-lost {
        background: #ffeced;
        color: #ff7b80;
    }

    .client-name {
        h2 {
            font-size: 28px;
            font-weight: 300;
            margin-bottom: 5px;
        }

        span {
            display: block;
            font-size: 12px;

            strong {
                font-weight: 600;
                margin-left: 10px;
            }
        }
    }

    .actions {
        display: flex;

        button {
            margin-top: 0;
            font-size: 14px;
            padding: 5px 20px;
            border-radius: 5px;

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    .vue-tabs {
        .nav-tabs-navigation {
            .nav-tabs-wrapper {
                .nav.nav-tabs {
                    display: flex;
                    justify-content: space-between;

                    li {
                        a {
                            padding: 15px;
                            font-size: 14px;
                            color: #383752;

                            span {
                                display: flex;
                                align-items: center;

                                i {
                                    margin-right: 8px;
                                }
                            }

                            &:hover {
                                background-color: transparent;
                                border-color: transparent;
                            }
                        }

                        &.active {
                            .active_tab {
                                background-color: transparent;
                                border: 0;
                                color: var(--secondary-color);
                            }
                        }
                    }
                }
            }
        }

        .tab-content {
            min-height: 68px;
        }
    }

    .lead-options {
        display: inline-block;
        margin-left: 10px;

        .bp-dropdown {
            &__btn {
                box-shadow: inset 0 0 0 1px #cbcccd,
                    0 1px 2px rgba(38, 41, 44, 0.08);
                border-radius: 5px;
                display: inline-block;
                padding: 5px 10px !important;
            }

            &__body {
                width: 150px;

                .option-row {
                    padding: 3px 10px;

                    &:hover {
                        background-color: rgba(38, 41, 44, 0.08);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
// fix the width of the stopwatch
// so it doesn't move elements next to it
.lead-stopwatch {
    width: 86px;
    display: inline-block;
}

.leads {
    max-width: 1485px;
}

.cards-column {
    max-width: 500px;
}

.activities-column {
    padding-left: 10px;
}

.is-deleting {
    pointer-events: none;
    &::after {
        content: "";
        opacity: 0.4;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        z-index: 1;
    }
}
</style>
