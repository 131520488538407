import OrganizationsForm from "../organizations/form";

export default {
    components: {
        OrganizationsForm
    },
    data() {
        return {
            organization: null,
            orgId: null
        };
    },
    methods: {
        async fetchOrganization() {
            await axios({
                url: `/organizations/${this.orgId}`
            }).then(({ data }) => {
                this.organization = data;
            });
        }
    }
}
