<template>
    <div v-show="valid" class="messages-body">
        <Head :connection="connection" :person="person" />
        <Body :messages="messages" :identity="identity" />
        <Controls @send="sendMessage" />
    </div>
</template>

<script>
import { Client } from "@twilio/conversations";
import Controls from "./Controls.vue";
import Head from "./Head.vue";
import Body from "./Body.vue";

export default {
    name: "Messages",
    components: {
        Controls,
        Head,
        Body
    },
    props: {
        person: {
            type: Object,
            default: () => ({})
        },
        identity: {
            type: String,
            default: () => ""
        }
    },
    data() {
        return {
            client: null,
            token: window.localStorage.getItem("twilio.token.chat"),
            connection: "disconnected",
            messages: [],
            conversation: null,
            data: {},
            valid: false
        };
    },
    mounted() {
        this.initClient();
        this.checkChat(this.$route.params.id);
    },
    methods: {
        initClient() {
            const client = new Client(this.token);
            this.client = client;

            client.on("connectionStateChanged", (state) => {
                this.connection = state;
            });

            client.on("conversationJoined", async(conversation) => {
                const messagesRaw = await conversation.getMessages();
                const messages = this.parseMessages(messagesRaw);

                this.updateMessages(messages);
                this.conversation = conversation;

                conversation.on("messageAdded", async() => {
                    const messagesRaw = await conversation.getMessages();
                    const messages = this.parseMessages(messagesRaw);

                    this.updateMessages(messages);
                });
            });
        },
        updateMessages(messages) {
            this.messages = [...messages];
        },
        parseMessages(messagesRaw) {
            return messagesRaw.items.map((message) => {
                return {
                    body: message.body,
                    autor: message.author
                };
            });
        },
        sendMessage(text) {
            this.conversation.sendMessage(text);
        },
        async getPerson(id) {
            if (id) {
                return (
                    await axios({
                        url: `peoples/${id}`,
                        method: "GET"
                    })
                )?.data;
            }
        },
        async checkChat(id) {
            const { data } = await axios({
                url: `/leads/${id}/chat`,
                method: "GET"
            });

            if (data !== null) {
                this.valid = true;
            }
        }
    }
};
</script>

<style scoped>
.messages-body {
    background-color: #ffff;
    height: 700px;
    border: 1px solid #ececec;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
</style>
