<template>
    <div
        v-if="(data && data.vehicle_of_interest) || data.vehicle_trade_in"
        class="contact-box"
    >
        <div class="card">
            <div class="card-title">
                <div>
                    <i class="fa-solid fa-file mr-2" />
                    <span> Lead Information </span>
                </div>
                <div
                    style="
                        border: solid 1px #a0a0a0;
                        padding: 3px;
                        border-radius: 3px;
                        font-size: 12px;
                        cursor: pointer;
                    "
                    @click="$modal.show('lead-info')"
                >
                    <span> Read More </span>
                    <i class="fa-solid fa-question mr-0" />
                </div>
            </div>
            <div class="card-body">
                <div class="contact">
                    <template>
                        <div class="row">
                            <div class="col-4 data-label">
                                <b>Vehicle</b>
                            </div>
                            <div class="col-8">
                                <span
                                    >{{ data.vehicle_of_interest.yearFrom }}
                                    {{ data.vehicle_of_interest.make }}
                                    {{ data.vehicle_of_interest.model }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 data-label">
                                <b>Suggested Price</b>
                            </div>
                            <div class="col-8">
                                <span>
                                    {{
                                        new Intl.NumberFormat("us-EN", {
                                            style: "currency",
                                            currency: "USD",
                                        }).format(
                                            data.vehicle_of_interest.price
                                        ) || ""
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 data-label">
                                <b>VIN</b>
                            </div>
                            <div class="col-8">
                                <span>{{ data.vehicle_of_interest.vin }} </span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="lead-info"
            height="auto"
            @before-open="beforeOpen"
            @before-close="beforeClose()"
        >
            <div class="person-modal">
                <div class="row modal-header">
                    <div class="col d-flex align-items-center">
                        <h5 style="color: #1975bc">Lead Information</h5>
                        <a
                            class="ml-auto"
                            href="#"
                            @click="$modal.hide('lead-info')"
                            >x</a
                        >
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div
                            slot="container"
                            class="row m-b-20"
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                justify-items: center;
                            "
                        >
                            <h4 class="mx-auto">Car Information</h4>
                            <div
                                class="mx-auto"
                                style="display: flex; flex-direction: row"
                            >
                                <div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Vehicle </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2">Price </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> VIN </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Condition </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Model </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Make </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Year </span>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                data.vehicle_of_interest
                                                    .yearFrom || ""
                                            }}
                                            {{
                                                data.vehicle_of_interest.make ||
                                                ""
                                            }}
                                            {{
                                                data.vehicle_of_interest
                                                    .model || ""
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                new Intl.NumberFormat("us-EN", {
                                                    style: "currency",
                                                    currency: "USD",
                                                }).format(
                                                    data.vehicle_of_interest
                                                        .price
                                                ) || ""
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                data.vehicle_of_interest.vin ||
                                                ""
                                            }}</span
                                        >
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                data.vehicle_of_interest.isNew
                                                    ? "New"
                                                    : "Used"
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                data.vehicle_of_interest
                                                    .model || ""
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                data.vehicle_of_interest.make ||
                                                ""
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                data.vehicle_of_interest
                                                    .yearFrom || ""
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <h4 class="mx-auto mt-3">
                                Person Comment and Request
                            </h4>

                            <div
                                class="m x-auto"
                                style="
                                    display: flex;
                                    flex-direction: row;
                                    margin-left: 180px;
                                "
                            >
                                <div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Comment </span>
                                    </div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Test Drive </span>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{ form.description || "..." }}
                                        </span>
                                    </div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: start"
                                    >
                                        <span class="mx-2">
                                            {{
                                                data.vehicle_of_interest
                                                    .test_drive
                                                    ? "Yes"
                                                    : "No"
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <h4 class="mx-auto mt-3">Trade in information</h4>

                            <div
                                class="m x-auto"
                                style="
                                    display: flex;
                                    flex-direction: row;
                                    margin-left: 140px;
                                "
                            >
                                <div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Down payment </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Model </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Make </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Year </span>
                                    </div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2"> Condition </span>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        class="mt-2 w-full"
                                        style="text-align: end"
                                    >
                                        <span class="mx-2">
                                            {{
                                                (data.vehicle_trade_id
                                                    ? data.vehicle_trade_id
                                                    : data.vehicle_trade_in
                                                ).down_payment || ""
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                        "
                                    >
                                        <span class="mx-2">
                                            {{
                                                (data.vehicle_trade_id
                                                    ? data.vehicle_trade_id
                                                    : data.vehicle_trade_in
                                                ).model
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                        "
                                    >
                                        <span class="mx-2">
                                            {{
                                                (data.vehicle_trade_id
                                                    ? data.vehicle_trade_id
                                                    : data.vehicle_trade_in
                                                ).make
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        class="mt-2 w-full"
                                        style="
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                        "
                                    >
                                        <span class="mx-2">
                                            {{
                                                (data.vehicle_trade_id
                                                    ? data.vehicle_trade_id
                                                    : data.vehicle_trade_in
                                                ).year
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        class="mt-2 w-full"
                                        style="
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                        "
                                    >
                                        <span class="mx-2">
                                            {{
                                                (data.vehicle_trade_id
                                                    ? data.vehicle_trade_id
                                                    : data.vehicle_trade_in
                                                ).isNew
                                                    ? "New"
                                                    : "Used"
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    name: "LeadInfoBox",
    props: {
        data: {
            type: Object,
            default() {
                return {
                    custom_fields: {
                        vehicle_of_interest: {},
                        vehicle_trade_in: {},
                    },
                    description: "",
                };
            },
        },
        form: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            isEditing: false,
        };
    },
    watch: {
        organizationId() {
            this.fetchData();
        },
    },
    async created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.orgId = this.organizationId;

            if (this.orgId > 0) {
                await this.fetchOrganization();
            } else {
                this.organization = null;
            }
        },
        openOrganizationModal() {
            this.$modal.show("organization", {
                organization: this.organization,
            });
        },
        saveOrganization(formData) {
            this.isEditing = false;
            this.organization = formData;
        },
    },
};
</script>

<style lang="scss">
.organization-form--footer {
    margin-top: 15px;
}

.contact-box {
    .card {
        padding: 0;

        .card-title {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            border-bottom: 1px solid #e1e8ed;
            margin-bottom: 0;

            i {
                cursor: pointer;
            }
        }

        .card-body {
            .contact {
                padding: 10px;
                border-bottom: 1px dashed #e1e8ed;
                position: relative;

                .row {
                    padding: 7px 0;

                    a {
                        color: var(--secondary-color);
                        text-decoration: none;
                    }

                    div.data-label {
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                .btn {
                    position: absolute;
                    right: 25px;
                    margin-top: 10px;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    .organization-form {
        .row {
            label {
                margin-bottom: 0;
            }

            .form-label {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                padding-right: 0;

                @media (max-width: 1600px) {
                    font-size: 12px;
                }
            }
        }

        &--footer {
            div:first-child {
                display: flex;
                justify-content: flex-end;
            }

            .btn {
                margin-left: 10px;
                position: static !important;
            }
        }
    }
}
</style>
