<template>
    <div class="form activity-widget">
        <div class="activity-controls">
            <div
                class="mr-2 ml-1"
                :class="{ error: error_name }"
                style="width: 100%"
            >
                <input
                    v-model="name"
                    :placeholder="activity_types[activity_type].name"
                    type="text"
                />
            </div>

            <div class="mb-3 activity-controls-buttons">
                <el-tooltip effect="dark" content="Tasks" placement="top-start">
                    <button
                        class="mx-1 activity-type-button"
                        :class="{
                            'activity-type-button-selected':
                                activity_type === 'Tasks',
                        }"
                        @click="selectType('Tasks')"
                    >
                        <i
                            class="fa fa-check-circle-o"
                            style="font-size: 19px"
                        />
                    </button>
                </el-tooltip>

                <el-tooltip
                    effect="dark"
                    content="Follow-up"
                    placement="top-start"
                >
                    <button
                        class="mx-1 activity-type-button"
                        :class="{
                            'activity-type-button-selected':
                                activity_type === 'Follow-up',
                        }"
                        @click="selectType('Follow-up')"
                    >
                        <i class="fa fa-plus" style="font-size: 19px" />
                    </button>
                </el-tooltip>

                <el-tooltip
                    effect="dark"
                    content="Meeting"
                    placement="top-start"
                >
                    <button
                        class="mx-1 activity-type-button"
                        :class="{
                            'activity-type-button-selected':
                                activity_type === 'Meeting',
                        }"
                        @click="selectType('Meeting')"
                    >
                        <i class="fa fa-users" style="font-size: 15px" />
                    </button>
                </el-tooltip>

                <el-tooltip effect="dark" content="Visit" placement="top-start">
                    <button
                        class="mx-1 activity-type-button"
                        :class="{
                            'activity-type-button-selected':
                                activity_type === 'Visit',
                        }"
                        @click="selectType('Visit')"
                    >
                        <i
                            class="fa-solid fa-person-walking"
                            style="font-size: 17px"
                        />
                    </button>
                </el-tooltip>
            </div>
        </div>

        <div class="activity-time">
            <div class="mr-2 ml-1 mt-1">
                <i class="fa fa-clock-o" style="color: var(--base-color)" />
            </div>

            <div class="time-container mb-3">
                <DatePicker
                    :type="'datetime'"
                    v-model="date_end_raw"
                    @change="selectDate"
                    placeholder="Select datetime"
                    :show-time-panel="showTimePanel"
                    @close="handleOpenChange"
                    format="hh:mm a"
                >
                    <template v-slot:input>
                        <div
                            class="mx-1 tag-time"
                            :class="{ error: error_date }"
                            @click="end_date_value = !end_date_value"
                        >
                            <span style="margin-right: 40px">
                                {{ date_end }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:footer>
                        <div
                            class="mx-btn mx-btn-text"
                            @click="toggleTimePanel"
                        >
                            {{ showTimePanel ? "Select Date" : "Select Time" }}
                    </div>
                    </template>
                </DatePicker>
                <!-- <DatePicker
                    :type="'datetime'"
                    v-model="date_end_raw"
                    :open="!end_date_value"
                    @change="selectDate"
                >
                    <template v-slot:input>
                        <div
                            v-if="end_date_value"
                            class="mx-1 tag-time"
                            :class="{ error: error_date }"
                            @click="end_date_value = !end_date_value"
                        >
                            <span style="margin-right: 40px">
                                {{ date_end }}
                            </span>
                        </div>
                    </template>
                </DatePicker> -->
            </div>
        </div>

        <div v-show="false" style="display: flex; flex-direction: row">
            <div class="mr-2 ml-1">
                <i class="fas fa-bell" style="color: var(--base-color)" />
            </div>

            <div class="activity-notifications">
                <div
                    v-for="(_, index) in items"
                    :key="_"
                    class="activity-notification-item"
                >
                    <select class="type-notification">
                        <option value="email">E-mail notification</option>
                        <option value="notification">Notification</option>
                    </select>

                    <input
                        min="1"
                        type="number"
                        class="type-notification type-time"
                        value="30"
                    />

                    <select class="type-notification">
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                        <option value="days">Days</option>
                    </select>

                    <div
                        v-if="items.length > 1"
                        class="mx-1 cursor-pointer"
                        style="color: #d2d2d2"
                        @click="removeItem(index)"
                    >
                        <i class="fas fa-times" />
                    </div>

                    <div
                        class="flex flex-row justify-items-center items-center mx-3"
                        style="
                            color: var(--base-color);
                            text-decoration: underline;
                        "
                    >
                        <span
                            v-if="items.length < 3"
                            class="text-lg text-center mb-1"
                            style="text-decoration: none; cursor: pointer"
                            @click="items.push(0)"
                        >
                            +

                            <span class="text-lg"> Add anoter one </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="activity-description">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 15 15"
                class="mr-2"
                style="color: var(--base-color)"
            >
                <g transform="translate(-811 -115.6)">
                    <g transform="translate(811 116)">
                        <rect
                            width="15"
                            height="15"
                            transform="translate(0 -0.4)"
                            fill="none"
                        />
                        <path
                            d="M14.146,6.308V3.2A40.744,40.744,0,0,0,9.917,3H4.238A1.242,1.242,0,0,0,3,4.238v8.669a1.242,1.242,0,0,0,1.238,1.238h8.669a1.242,1.242,0,0,0,1.238-1.238V7.229C14.146,6.9,14.137,6.7,14.146,6.308ZM5.477,5.477H9.812V6.715H5.477Zm6.192,6.192H5.477V10.431h6.192Zm0-2.477H5.477V7.954h6.192Z"
                            transform="translate(-1.142 -1.142)"
                            fill="var(--base-color)"
                        />
                        <g
                            transform="translate(10 0.6)"
                            fill="var(--base-color)"
                            stroke="#fff"
                            stroke-width="0.7"
                        >
                            <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
                            <circle cx="2.5" cy="2.5" r="2.15" fill="none" />
                        </g>
                    </g>
                </g>
            </svg>
            <textarea v-model="description" class="description" />
        </div>

        <div class="activity-tagget">
            <div
                v-if="
                    static_info.contact ||
                    static_info.related_org ||
                    static_info.lead_owner
                "
                class="ml-1 mr-2 mt-1"
            >
                <i class="fa-solid fa-user" style="color: var(--base-color)" />
            </div>

            <div
                class="flex flex-col space-y-3 mb-3"
                style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                "
            >
                <div v-if="static_info.contact" class="mx-1 tag-owner-name">
                    {{ static_info.contact }}
                </div>

                <div v-if="static_info.related_org" class="mx-1">
                    <i
                        class="fa-regular fa-building"
                        style="color: var(--base-color)"
                    />
                </div>

                <div v-if="static_info.related_org" class="mx-1 tag-owner-name">
                    {{ static_info.related_org }}
                </div>

                <div
                    v-if="static_info.lead_owner"
                    class="mx-1"
                    style="color: #707070"
                >
                    Owner
                </div>

                <div v-if="static_info.lead_owner" class="mx-1 tag-owner-name">
                    {{ static_info.lead_owner }}
                </div>
            </div>
        </div>

        <div class="activity-toolbar">
            <div class="activity-check">
                <label for="check" class="mt-2 ml-2">Mark as complete</label>
                <input
                    v-model="complete"
                    type="checkbox"
                    name="check"
                    class="check"
                />
            </div>

            <div>
                <button class="btn btn-discard" @click.prevent="discard">
                    Discard changes
                </button>

                <button
                    class="btn btn-post ml-2"
                    style="background-color: var(--base-color)"
                    @click.prevent="save"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { prettyDate } from "@/utils/helpers";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
    components: {
        DatePicker,
    },
    props: {
        startDateProp: {
            type: Date,
            default: null,
        },
        endDateProp: {
            type: Date,
            default: null,
        },
        nameProp: {
            type: String,
            default: null,
        },
        descriptionProp: {
            type: String,
            default: null,
        },
        completeProp: {
            type: Boolean,
            default: null,
        },
        activityTypeProp: {
            type: String,
            default: null,
        },
        leadId: {
            type: Number,
            default: null,
        },
        leadProp: {
            type: Number,
            default: null,
        },
        staticInfoProp: {
            type: Object,
            default: null,
        },
        resetWidgetDiscard: {
            type: Boolean,
            default: false,
        },
        resetWidgetSend: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            items: [0],
            date_start_raw: null,
            start_date_value: true,
            date_end_raw: null,
            end_date_value: true,
            activity_type: "Tasks",
            name: "",
            description: "",
            complete: false,
            static_info: {
                related_org: null,
                lead_owner: null,
                contact: null,
            },
            activity_types: {
                Tasks: {
                    id: 4,
                    name: "Tasks",
                },
                Meeting: {
                    id: 6,
                    name: "Meeting",
                },
                Visit: {
                    id: 7,
                    name: "Visit",
                },
                "Follow-up": {
                    id: 5,
                    name: "Follow-up",
                },
            },
            error_name: false,
            error_date: false,
            showTimePanel: false,
            showTimeRangePanel: false,
        };
    },
    computed: {
        date_start() {
            return this.date_start_raw
                ? prettyDate(this.date_start_raw)
                : "YYYY-MM-DD";
        },
        date_end() {
            return this.date_end_raw
                ? prettyDate(this.date_end_raw)
                : "YYYY-MM-DD";
        },
    },
    async mounted() {
        this.asignProps();

        if (this.leadId && typeof this.leadId === "number") {
            axios({
                url: `/leads/${this.leadId}`,
            })
                .then((response) => {
                    const org = response?.data?.organization?.name;
                    const leadOwner = response?.data?.owner?.full_name;
                    const contact = response?.data?.firstname;

                    org && (this.static_info.related_org = org);
                    leadOwner && (this.static_info.lead_owner = leadOwner);
                    contact && (this.static_info.contact = contact);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    watch: {
        date_end_raw () {
            const d = new Date(this.date_end_raw);

            if (d.getHours() === 0) {
                d.setHours(23);
                d.setMinutes(59);

                this.date_end_raw = d;
            }
        }
    },
    methods: {
        selectDate() {
            this.end_date_value = !this.end_date_value;
        },
        removeItem(item) {
            if (this.items.length > 1) {
                this.items.splice(item, 1);
            }
        },
        selectType(type) {
            this.activity_type = type;
        },
        save() {
            const notify = this.validate();

            if (notify.name || notify.end_date) {
                this.error_name = notify.name;
                this.error_date = notify.end_date;

                setTimeout(() => {
                    this.error_name = false;
                    this.error_date = false;
                }, 3800);

                return this.$notify({
                    title: "Error",
                    text:
                        "Name and Date fields must be filled before creating an activity",
                    type: "error",
                });
            } else {
                const date_end = new Date(this.date_end_raw);
                this.$emit("save", {
                    title:
                        this.name ||
                        this.activity_types[this.activity_type].name,
                    description: this.description,
                    complete: this.complete,
                    date_start: new Date(),
                    date_end: date_end,
                    activity_type: {
                        id: this.activity_types[this.activity_type].id,
                        name: this.activity_types[this.activity_type].name,
                    },
                });

                this.resetWidget(this.resetWidgetSend);
            }
        },
        resetWidget(condition) {
            if (condition) {
                this.items = [0];
                this.date_start_raw = null;
                this.start_date_value = true;
                this.date_end_raw = null;
                this.end_date_value = true;
                this.activity_type = "Tasks";
                this.name = "";
                this.description = "";
                this.complete = false;
            }
        },
        discard() {
            this.$emit("discard", {
                title:
                    this.name || this.activity_types[this.activity_type].name,
                description: this.description,
                complete: this.complete,
                date_start: this.date_start_raw,
                date_end: this.date_end_raw,
                activity_type: {
                    id: this.activity_types[this.activity_type].id,
                    name: this.activity_types[this.activity_type].name,
                },
            });

            this.resetWidget(this.resetWidgetDiscard);
        },
        asignProps() {
            this.nameProp && (this.name = this.nameProp);
            this.endDateProp && (this.date_end_raw = this.endDateProp);
            // this.startDateProp && (this.date_start_raw = this.startDateProp);
            this.descriptionProp && (this.description = this.descriptionProp);
            this.activityTypeProp &&
                (this.activity_type = this.activityTypeProp);

            typeof this.completeProp === "boolean" &&
                (this.complete = this.completeProp);

            if (this.staticInfoProp !== null) {
                const org = this.staticInfoProp?.related_org;
                const leadOwner = this.staticInfoProp?.lead_owner;
                const contact = this.staticInfoProp?.contact;

                org && (this.static_info.related_org = org);
                leadOwner && (this.static_info.lead_owner = leadOwner);
                contact && (this.static_info.contact = contact);
            }
        },
        validate() {
            return {
                name: this.name === "",
                end_date: this.date_end_raw === null,
            };
        },
        toggleTimePanel() {
            this.showTimePanel = !this.showTimePanel;
        },
        toggleTimeRangePanel() {
            this.showTimeRangePanel = !this.showTimeRangePanel;
        },
        handleOpenChange() {
            this.showTimePanel = false;
        },
        handleRangeClose() {
            this.showTimeRangePanel = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.error {
    border: dashed 1px #ff8484;
}

.activity-widget {
    .activity-notifications {
        display: flex;
        flex-direction: column;

        .activity-notification-item {
            display: flex;
            flex-direction: row;
            justify-content: start;
            margin-bottom: 10px;
            align-items: center;
        }
    }

    .activity-controls {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        align-items: center;

        input {
            width: 100%;
            outline: 0;
            border-width: 0 0 2px;
            border-color: #e8e8e8;
            padding-right: 15px;
        }

        .activity-controls-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    .activity-type-button {
        border: 2px solid #efefef;
        color: #707070;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #ffffff;
        cursor: pointer;
        outline: none;
    }

    .activity-type-button-selected {
        // border: 2px solid #c4f1f7;
        border: 2px solid #5da5dbcc;
        // background-color: #f4fdfe;
        background-color: #edf5fc;
        // color: #1fcae5;
        color: var(--base-color);
    }

    .activity-time {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .tag-time {
            background-color: #f7f7f7;
            color: #707070;
            padding: 5px;
            border-radius: 5px;
        }

        .time-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    .activity-tagget {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-items: flex-start;
        justify-items: center;
        align-content: center;

        .tag-owner-name {
            background-color: #f7f7f7;
            color: #707070;
            padding: 5px;
            border-radius: 5px;
        }
    }

    .activity-description {
        border-radius: 6px;
        min-height: 110px;
        display: flex;
        justify-items: start;
        align-items: flex-start;

        .description {
            border: 1px solid #ececec;
            border-radius: 6px;
            min-height: 140px;
            height: 110px;
            padding: 8px;
            resize: none;
            width: 100%;
        }

        .description:focus {
            outline: none;
        }
    }

    .type-notification {
        background-color: #f7f7f7;
        outline: none;
        width: 130px;
        padding: 5px;
        border-radius: 5px;
        border: none;
        margin-right: 20px;
        margin-left: 4px;
        padding: 4px;
        color: #707070;
    }

    .type-notification {
        background-color: #f7f7f7;
        outline: none;
        width: 130px;
        padding: 5px;
        border-radius: 5px;
        border: none;
        margin-right: 20px;
        margin-left: 4px;
        padding: 4px;
        color: #707070;
    }

    .type-time {
        width: 50px;
    }

    .activity-toolbar {
        display: flex;
        padding: 10px 0 30px;
        justify-content: space-between;
        position: relative;
        margin-top: 5px;

        .activity-check {
            display: flex;
            flex-direction: row-reverse;
            justify-items: center;
            align-items: center;
            justify-content: center;
            justify-content: center;

            .check {
                -ms-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -webkit-transform: scale(1.2);
                -o-transform: scale(1.2);
                transform: scale(1.2);
                padding: 8px;
            }
        }

        .btn {
            width: 85px;
            font-size: 16px;
            letter-spacing: 0.07px;
            padding: 6px 0;
            border-radius: 2px;
        }

        .btn-discard {
            background: #bbbbbb26;
            color: #9b9b9b;
            width: 150px;
        }

        .btn-post {
            background: #35d8f2;
            color: #fff;
        }
    }

    .toolbar-action {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
