
<template>
    <div :class="{'no-visible': !files.length }">
        <el-upload
            ref="uploader"
            list-type="picture-card"
            :on-change="handleChange"
            :accept="accept"
            :before-upload="beforeUpload"
            :action="endpoint"
            :headers="{ Authorization: token }"
            :file-list="files"
            :disabled="disabled"
        >
            <i slot="default" class="el-icon-plus" />
            <div slot="file" slot-scope="{file}" style="height:100%">
                <img
                    v-if="isImage(file.name)"
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                >
                <div v-else class="document-preview" :title="file.name">
                    <div class="document-preview__type uppercase">
                        {{ getFileType(file.name) }}
                    </div>
                </div>
                <span v-if="isImage(file.name)" class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                    >
                        <i class="el-icon-zoom-in" />
                    </span>
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                    >
                        <i class="el-icon-download" />
                    </span>
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                    >
                        <i class="el-icon-delete" />
                    </span>
                </span>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        accept: {
            type: String,
            default: "image/*,.pdf"
        },
        sizeLimit: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            isLoading: false,
            files: [],
            baseURL: process.env.VUE_APP_BASE_API_URL,
            token: this.$store.state.User.token || Cookies.get("token")
        };
    },
    computed: {
        endpoint() {
            return this.baseURL + "/filesystem";
        }
    },
    watch: {
        value:{
            handler(values) {
                this.files = values;
            },
            immediate: true
        },
        files:{
            handler(files) {
                this.$emit("input", files);
            },
            immediate: true
        }
    },
    methods: {
        isImage(name) {
            const images = ["png", "jpg", "PNG"];
            const type = this.getFileType(name);
            if (type) {
                return images.includes(type);
            }
        },
        getFileType(name) {
            const type = name.split(".").slice(-1);
            if (type && type.length) {
                return type[0];
            }
        },
        handleRemove(file) {
            this.$emit("removed", file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        openUploader() {
            this.$refs.uploader.$el.querySelector(".el-upload").click()
        },
        handleChange(_file, fileList) {
            this.files = fileList;
        },
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < this.sizeLimit;

            if (!isLt2M) {
                this.$notify({
                    title: "Error",
                    text: `File size can not exceed ${this.sizeLimit}MB!`,
                    type: "error"
                });
                return false
            }
        }
    }
}
</script>


<style lang="scss">
.no-visible {
    width: 0px;
    height: 0px;
    opacity: 0;

    .el-upload--picture-card, .el-icon-plus, .el-icon-plus::before {
        width: 0 !important;
        height: 0 !important;
        opacity: 0 !important;
        display: none;
    }
}
</style>

<style lang="scss" scoped>
.no-visible {
    width: 0px;
    height: 0px;
    opacity: 0;

    .el-upload--picture-card {
        width: 0 !important;
        height: 0 !important;
        opacity: 0 !important;
    }
}

.document-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: red;
    font-weight: bold;
    cursor: pointer;
}
</style>
