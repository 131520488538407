<template>
    <validation-observer
        ref="observer"
        :class="{ card: isStandalone && !modal }"
        class="people-form custom"
        tag="form"
        @submit.prevent="beforeSave()"
    >
        <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="name"
            slim
        >
            <div
                style="
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    height: 100%;
                "
            >
                <div
                    :class="{ 'form-group': isStandalone && !modal }"
                    class="row"
                    style="width: 100%; display: flex; flex-direction: column;"
                >
                    <div v-if="!modal" class="col-3 form-label">
                        <label for="name" class="control-label">Name</label>
                    </div>
                    <div class="col-9" style="width: 100%">
                        <input
                            id="name"
                            v-model="personData.name"
                            style="width: max-content; width: 400px"
                            class="form-control"
                            placeholder="Name"
                            type="text"
                            name="name"
                        >
                    </div>
                </div>
                <div v-if="errors[0]" class="row">
                    <div
                        :class="{ 'offset-1': !isStandalone }"
                        class="col-12 text-danger"
                    >
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </validation-provider>
        <div class="flex">
            <div v-for="(type, tIdx) in contactsTypes" :key="`contact-${tIdx}`" style="display: flex; flex-direction: column; width: 450px;">
                <div
                    v-for="(contact, cIdx) in contactsData[type.id]"
                    :key="`contact-${tIdx}-${cIdx}`"
                    :class="{
                        'form-group': isStandalone && !modal,
                        'mb-0': isStandalone && !modal,
                    }"
                    class="row pb-0 my-2"
                    :style="`${modal ? 'display: flex;' : ''}`"
                >
                    <div v-if="!modal" class="form-label" :class="`${modal ? 'ml-3' : 'col-3'}`">
                        <label
                            v-if="cIdx === 0"
                            :for="`contact-${cIdx}`"
                            class="control-label"
                        >{{ type.name }}</label>
                    </div>
                    <div class="col">
                        <input
                            :id="`contact-${cIdx}`"
                            v-model="contact.value"
                            :name="`${type.name}-${cIdx}`"
                            :placeholder="type.name"
                            style="width: 400px"
                            class="form-control"
                            type="text"
                        >
                    </div>
                    <div
                        v-if="contactsData[type.id].length > 1"
                        class="contact-delete"
                    >
                        <i
                            class="fas fa-trash"
                            @click="removeContact(type.id, cIdx)"
                        />
                    </div>
                </div>
                <div class="row pt-0 add-more">
                    <div class="col-12">
                        <a href="#" @click.prevent="addContact(type.id)">
                            Add one more
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row people-form--footer pt-3">
            <div :class="footerClass">
                <button
                    :class="cancelButtonClass"
                    type="button"
                    @click="$emit('cancel')"
                >
                    Cancel
                </button>
                <button :class="saveButtonClass" type="submit">
                    {{ saveText }}
                </button>
            </div>
        </div>
    </validation-observer>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PeopleSearchResults from "../peoples/search-results";
// const { GwBrowse } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);

export default {
    name: "PeoplesForm",
    components: {
        // eslint-disable-next-line vue/no-unused-components
        PeopleSearchResults,
        ValidationObserver,
        ValidationProvider
    },
    props: {
        contacts: {
            type: Object,
            required: true
        },
        contactsTypes: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            default: "inline"
        },
        person: {
            type: Object,
            required: true
        },
        modal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            contactsData: _cloneDeep(this.contacts),
            isEditing: typeof this.person.id !== "undefined",
            personData: _cloneDeep(this.person)
        };
    },
    computed: {
        cancelButtonClass() {
            return this.isStandalone
                ? "btn btn-danger mr-3 btn-fixed-height"
                : "btn btn-danger mr-2 btn-normal";
        },
        footerClass() {
            return this.isStandalone
                ? "col-12 d-flex justify-content-end"
                : "col-12";
        },
        isStandalone() {
            return this.mode === "standalone";
        },
        saveButtonClass() {
            return this.isStandalone
                ? "btn btn-primary"
                : "btn btn-primary btn-normal";
        },
        saveText() {
            return this.isStandalone && !this.isEditing ? "Create" : "Save";
        }
    },
    methods: {
        addContact(typeId) {
            const newContact = {
                contacts_types_id: typeId,
                value: ""
            };

            this.contactsData[typeId].push(newContact);
        },
        checkDuplicates() {
            return axios({
                url: "/peoples",
                params: {
                    q: this.getDuplicateSearch(),
                    limit: 200,
                    sort: "created_at|DESC"
                }
            });
        },
        getDuplicateSearch() {
            const emailList = this.getContactSearch("Email");
            const phoneList = this.getContactSearch("Phone");
            const searchName = `name:%${this.personData.name.trim()}%`;
            const searchEmail = (emailList && `email_clean:${emailList}`) || "";
            const searchPhone = (phoneList && `phone_clean:${phoneList}`) || "";
            const searchList = [searchName, searchEmail, searchPhone];

            return `(${searchList.filter((v) => v).join(";")})`;
        },
        getContactSearch(contactType) {
            const contactTypeId = Object.values(this.contactsTypes).find(
                (type) => type.name === contactType
            ).id;
            const contactList = this.contactsData[contactTypeId]
                .reduce((list, entry) => {
                    entry.value && list.push(`%${entry.value}%`);
                    return list;
                }, [])
                .join("|");

            return contactList;
        },
        removeContact(typeId, contactIndex) {
            this.contactsData[typeId].splice(contactIndex, 1);
        },
        async beforeSave() {
            const isFormValid = await this.$refs.observer.validate();

            if (!isFormValid) {
                return;
            }

            const { data: contactResults } = await this.checkDuplicates();

            if (!this.isEditing && this.isStandalone && contactResults.length) {
                this.$modal.show(
                    PeopleSearchResults,
                    {
                        appendParams: {
                            format: true,
                            limit: 10,
                            q: this.getDuplicateSearch()
                        },
                        cancel: () => {
                            this.$modal.hide("people-results");
                            this.$emit("cancel");
                        },
                        proceed: () => {
                            this.$modal.hide("people-results");
                            this.save();
                        }
                    },
                    {
                        name: "people-results",
                        height: "auto",
                        width: "60%",
                        classes: "v--modal people-results"
                    }
                );
            } else {
                this.save();
            }
        },
        async save() {
            let url = `/peoples/${this.person.id}`;
            let method = "PUT";
            const formData = { ...this.personData, contacts: [] };

            Object.keys(this.contactsData).forEach((typeId) => {
                formData.contacts = formData.contacts.concat(
                    this.contactsData[typeId]
                );
            });

            if (!this.isEditing && this.isStandalone) {
                url = "/peoples";
                method = "POST";
            }

            axios({
                url,
                method,
                data: formData
            })
                .then(() => {
                    this.$emit("save", formData);
                })
                .catch((error) => {
                    const message =
                        error?.response?.data?.errors?.message ||
                        "Something went wrong.";
                    this.$notify({
                        title: "Error",
                        text: message,
                        type: "error"
                    });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom {
    display: flex;
    flex-direction: column;
}

input {
    width: 100%;
}

a {
    text-decoration: underline;
}

.btn-fixed-height {
    height: 47px !important;
}

.people-form--footer {
    align-items: flex-end;
}
</style>

<style lang="scss">
.people-results {
    padding: 10px;

    .pc-top {
        .pagination.menu {
            padding-top: 0;
        }
    }

    .section-title {
        float: left;
        padding-top: 4px;
    }
}
</style>
