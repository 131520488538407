<template>
    <pipeline-end-active v-if="active" :height="height" :width="width" />
    <pipeline-end-default v-else-if="pass" :height="height" :width="width" />
    <pipeline-end-inactive v-else :height="height" :width="width" />
</template>

<script>
import PipelineEndActive from "./pipeline-end-active.vue";
import PipelineEndInactive from "./pipeline-end-inactive.vue";
import PipelineEndDefault from "./pipeline-end-default.vue";

export default {
    name: "PipelineEnd",
    components: {
        PipelineEndActive,
        PipelineEndInactive,
        PipelineEndDefault
    },
    props: {
        active: {
            type: Boolean,
            default: false
        },
        pass: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default() {
                return 37.127;
            }
        },
        width: {
            type: Number,
            default() {
                return 75.2;
            }
        }
    }
};
</script>
