<template>
    <div class="activities-box">
        <activity-tabs v-model="activeTab" :tabs="visibleTabs" />

        <updates-form
            v-if="activeTab == 'update'"
            :endpoint="messageEndpoint"
            :form-data="formData"
            @message-saved="$emit('message-saved', $event)"
        />

        <activity-form
            v-if="activeTab == 'activity'"
            :endpoint="activityEndpoint"
            @activity-saved="$emit('activity-saved', $event)"
        />

        <keep-alive v-if="showChat">
            <dealer-chat
                v-if="activeTab == 'chat' && formData.id"
                ref="chat"
                class="activities-box__chat"
                token-field="channel_client_token"
                endpoint="/chats"
                :show-header="false"
                :http-options="{ baseURL, headers: { Authorization: token }}"
                :display-full="false"
                :contacts="null"
                :receiver="formData.id"
                @action-called="openDealerList"
            />
        </keep-alive>

        <modal
            :draggable="false"
            :adaptive="true"
            :scrollable="true"
            :width="1000"
            name="dealer-modal"
            height="auto"
        >
            <dealer-vehicle-list
                :companies-id="formData.companies_id"
                @selected="processSelected"
                @cancel="closeDealerList"
            />
        </modal>
    </div>
</template>

<script>
import DealerVehicleList from "@c/dealer/vehicle-list";
import ActivityTabs from "./activity-tabs";
import ActivityForm from "./activity-form";
import UpdatesForm from "./updates-form";
import { mapGetters } from "vuex";

const { TwilioChat: DealerChat } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);
const TABS = { chat: "chat", update: "update", activity: "activity" };

export default {
    name: "ActivitiesBox",
    components: {
        DealerChat,
        DealerVehicleList,
        UpdatesForm,
        ActivityTabs,
        ActivityForm
    },
    props:{
        formData: {
            type: Object,
            default() {
                return {}
            }
        },
        showChat: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            baseURL: process.env.VUE_APP_BASE_API_URL,
            queryParams: {
                sort: "sort",
                page: "page",
                perPage: "limit"
            },
            token: this.$store.state.User.token || Cookies.get("token"),
            activeTab: "update"

        }
    },
    computed: {
        ...mapGetters({
            isChatActive: "Application/isChatActive"
        }),
        messageEndpoint() {
            return `/messages`;
        },
        activityEndpoint() {
            return `/activities`;
        },
        visibleTabs() {
            const activityTabs = [
                {
                    name: TABS.update,
                    label: "Update",
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g transform="translate(-811 -115.6)"><g transform="translate(811 116)"><rect width="15" height="15" transform="translate(0 -0.4)" fill="none"/><path d="M14.146,6.308V3.2A40.744,40.744,0,0,0,9.917,3H4.238A1.242,1.242,0,0,0,3,4.238v8.669a1.242,1.242,0,0,0,1.238,1.238h8.669a1.242,1.242,0,0,0,1.238-1.238V7.229C14.146,6.9,14.137,6.7,14.146,6.308ZM5.477,5.477H9.812V6.715H5.477Zm6.192,6.192H5.477V10.431h6.192Zm0-2.477H5.477V7.954h6.192Z" transform="translate(-1.142 -1.142)" fill="#707070"/><g transform="translate(10 0.6)" fill="#707070" stroke="#fff" stroke-width="0.7"><circle cx="2.5" cy="2.5" r="2.5" stroke="none"/><circle cx="2.5" cy="2.5" r="2.15" fill="none"/></g></g></g></svg>',
                    visible: true,
                    svg: true
                },
                {
                    name: TABS.chat,
                    label: "Message",
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 -1 10 10"><path d="M8.75,0H1.25A1.251,1.251,0,0,0,0,1.25V6.875a1.251,1.251,0,0,0,1.25,1.25H3.125V9.766a.235.235,0,0,0,.373.189l2.439-1.83H8.75A1.251,1.251,0,0,0,10,6.875V1.25A1.251,1.251,0,0,0,8.75,0Z" fill="#707070"/></svg>',
                    visible: this.showChat,
                    svg: true
                },
                {
                    name: TABS.activity,
                    label: "Activity",
                    icon: "fa-calendar-days",
                    visible: true,
                    svg: false
                }
            ];
            return activityTabs.filter(tab => tab.visible);
        }
    },
    watch: {
        "$route.fullPath": {
            handler() {
                if (this.$route.query.tab && this.$route.query.tab != this.activeTab) {
                    this.activeTab = this.$route.query.tab ;
                }
            },
            immediate: true
        },
        activeTab(value) {
            this.$router.replace({
                name: "edit-resource",
                params: {
                    id: this.$route.params.id,
                    resource: "leads"
                },
                query: {
                    tab: this.activeTab.toLowerCase()
                }
            })

            if (value == TABS.chat) {
                this.$nextTick(() => {
                    this.$refs.chat.focus()
                })
            }
        }
    },
    methods: {
        openDealerList() {
            this.$modal.show("dealer-modal");
        },
        closeDealerList() {
            this.$modal.hide("dealer-modal");
        },

        processSelected(items) {
            this.$modal.hide("dealer-modal");
            items.forEach((item) => {
                this.$refs.chat.sendMessage(item.message, item.attributes)
            })

        },

        axiosUpload(url) {
            return axios({
                method: "post",
                url
            })
        }
    }
}
</script>

<style lang="scss">
.activities-box {
    background: #FFF;
    border: 1px solid #ECECEC;
    border-radius: 10px;

    margin-bottom: 40px;

    .card {
        padding: 0;
    }

    &__chat {
        height: 500px;
    }
}

.tab.disabled a {
    color: gray !important;
}
</style>
