<template>
    <div class="feed-form">
        <quill-editor
            ref="quillEditor"
            v-model="formData.message.text"
            name="content"
            :options="editorOptions"
        />
        <small v-if="hasError && !formData.message.text" class="content-error text-danger"> This field is required </small>
        <div v-if="showGallery" class="images-container px-4 py-2">
            <feed-gallery
                ref="Gallery"
                v-model="formData.files"
                :accept="accept"
                :file-size="50"
                @removed="removeFile"
            />
        </div>
    </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import FeedGallery from "./feed-gallery";

export default {
    name: "FeedBaseForm",
    components: {
        quillEditor,
        FeedGallery
    },
    props: {
        showGallery: {
            type: Boolean,
            default: true
        },
        placeholder:{
            type: String,
            default: ""
        },
        feedData: {
            type: Object,
            default() {
                return {}
            }
        },
        accept: {
            type: String,
            default: "image/*,application/pdf"
        },
        value: {
            type: Object,
            required: true
        },
        submitKeyBind: {
            type: Object,
            default: () => ({
                key: "enter",
                ctrlKey: true
            })
        },
        focusOnMounted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formData: {
                leads_uuid: "",
                files: [],
                verb: "lead",
                message: {
                    text: ""
                }
            },
            hasError: false,
            isLoading: false
        }
    },
    computed: {
        editor() {
            return this.$refs.quillEditor;
        },
        editorOptions() {
            const self = this;
            return {
                placeholder: this.placeholder || "Add a new text...",
                modules: {
                    toolbar: false,
                    keyboard: {
                        bindings: [
                            {
                                ...this.submitKeyBind,
                                handler() {
                                    self.validateForm()
                                }
                            }
                        ]
                    }
                }
            }
        }
    },
    watch: {
        value: {
            handler(value) {
                this.clearForm();
                this.formData = { ...this.formData, ...value };
                this.hasError = false
            },
            immediate: true
        },
        formData(formData) {
            this.$emit("input", formData)
        }
    },
    mounted() {
        if (this.focusOnMounted) {
            this.focus();
        }
    },
    methods: {
        focus() {
            this.$refs.quillEditor.quill.focus();
        },

        removeFile(file) {
            this.formData.files = this.formData.files.filter(localFile => localFile.url != file.url);
        },

        clearForm() {
            this.formData.message = {
                text: ""
            }
            this.formData.files = [];
            this.hasError = false;
        },

        prepareMessage() {
            const formData = { ...this.formData }
            formData.files = this.getGalleryFiles(formData.files);
            return formData;
        },

        async validateForm() {
            const isValid = this.formData.message.text && this.formData.message.text.trim()
            let formData = {}

            if (!isValid) {
                this.hasError = true;
                return;
            }

            formData = this.prepareMessage();
            this.$emit("validated", formData)
        },

        getGalleryFiles(files) {
            return files.map(file => {
                const realFile = file.response && file.response.length ? file.response[0] : file;
                return this.formatFileSystem(realFile);
            });
        },

        openUploader() {
            this.$refs.Gallery.openUploader();
        },

        cancel() {
            this.clearForm();
            this.$emit("cancel")
        }
    }
}
</script>

<style lang="scss">
    .feed-form {
        background: white;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-top: 0px;
        border: none;
    }

    .ql-editor {
        padding: 12px 20px !important;
    }

    .form .ql-editor {
        min-height: 60px !important;
    }

    .ql-editor.ql-blank::before {
        padding-left: 5px;
        font-style: normal;
    }

    .ql-container.ql-snow {
        border: none;
    }

    .ql-mention-list-container {
        z-index: 200;
    }

    ul.ql-mention-list {
        border-radius: 4px;
        margin: 0 0 0 0;
        margin-top: 2px;
        padding: 0 0 0 0;
        list-style: none;
        border: 1px solid #eee;
        box-shadow: 4px 4px 4px #eee;
        background: white;
        z-index: 100;

        li {
            width: 100%;
            cursor: pointer;
            padding: 10px 15px;
            &:hover {
                background: rgba(30, 143, 255, 0.192);
            }
        }
    }

    .content-error {
        padding-left: 20px;
    }

    .feed-form {
        position: relative;
        &__send-comment {
            position: absolute;
            bottom: 5px;
            right: 15px;
            .btn {
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
        }
    }
</style>
