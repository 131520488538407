<template>
    <form class="p-4" @submit.prevent.stop="beforeSave()">
        <div v-if="changeOwnerMode" class="row">
            <div class="form-group col-md-12">
                <label for="owner">Lead Owner</label>
                <resource-selector
                    id="leadsOwner"
                    v-model="formData.owner"
                    v-validate="'required'"
                    :class="{invalid: errors.first('owner')}"
                    :custom-label="userLabel"
                    :disabled="disabled || !canEditLeadOwner"
                    :resource="formData.owner"
                    :searchable-by="['firstname','lastname', 'displayname']"
                    name="owner"
                    placeholder="Select a Lead Owner"
                    resource-id="id"
                    resource-url="/users?limit=250&filters=(user_active:1)&text="
                />
                <span class="text-danger">{{ errors.first("owner") }}</span>
            </div>
            <div class="col-md-6 offset-md-6 mt-1">
                <div class="actions d-flex justify-content-end">
                    <button class="btn btn-danger mr-2" type="button" @click.stop="$emit('cancel')">
                        Cancel
                    </button>
                    <button class="btn btn-primary">
                        {{ createButtonText }}
                    </button>
                </div>
            </div>
        </div>

        <div
            v-else-if="changeStageMode"
            class="row" style="height: 100%; display: flex; flex-direction: row; justify-content: space-between; align-content: space-between;">
            <div class="form-group col-md-12" style="margin-top: -30px; height: auto;">
                <label for="stage">Stage</label>
                <multiselect
                    v-model="formData.stage"
                    v-validate="'required'"
                    :allow-empty="false"
                    :options="stages"
                    :searchable="true"
                    :show-labels="false"
                    label="name"
                    name="stage"
                    placeholder="Select a stage"
                    track-by="id"
                    @open="openStage"
                    @close="closeStage"
                />
                <span class="text-danger">{{ errors.first("stage") }}</span>
            </div>
            <div class="col-md-6 offset-md-6" :style="isOpenStage ? 'margin-top: 210px;' : 'margin-top: 20px;'">
                <div class="actions d-flex justify-content-end">
                    <button class="btn btn-danger mr-2" type="button" @click.stop="$emit('cancel')">
                        Cancel
                    </button>
                    <button class="btn btn-primary">
                        {{ createButtonText }}
                    </button>
                </div>
            </div>
        </div>

        <div v-else class="row">
            <template v-if="!editMode">
                <!-- Person -->
                <div class="form-group col-md-6">
                    <label for="person">Person </label>
                    <resource-selector
                        v-if="modal === 'true'"
                        ref="personResource"
                        v-model="formData.people"
                        v-validate="{ required: !newPerson }"
                        :class="['person-select', { invalid: errors.first('person') }]"
                        :resource="formData.people"
                        :searchable-by="['name', 'email_clean', 'phone_clean']"
                        :searchable-by-process="searchableByProcess.person"
                        :show-add-resource="true"
                        :show-no-options="false"
                        :show-no-results="false"
                        name="person"
                        placeholder="Search for a person"
                        resource-id="id"
                        resource-label="person"
                        resource-url="/peoples"
                        @add-resource="addPerson"
                    >
                        <template slot="option" slot-scope="props">
                            <div>
                                {{ props.option.name }}
                            </div>
                            <small>{{ props.option.email }} | {{ props.option.phone }}</small>
                        </template>
                    </resource-selector>

                    <resource-selector
                        v-if="modal === true"
                        ref="personResource"
                        v-model="formData.person"
                        v-validate="{ required: !newPerson }"
                        :class="['person-select', { invalid: errors.first('person') }]"
                        :resource="formData.people"
                        :searchable-by="['name', 'email_clean', 'phone_clean']"
                        :searchable-by-process="searchableByProcess.person"
                        :show-add-resource="true"
                        :show-no-options="false"
                        :show-no-results="false"
                        name="person"
                        placeholder="Search for a person"
                        resource-id="id"
                        resource-label="person"
                        resource-url="/peoples"
                        @add-resource="addPerson"
                    >
                        <template slot="option" slot-scope="props">
                            <div>
                                {{ props.option.name }}
                            </div>
                            <small>{{ props.option.email }} | {{ props.option.phone }}</small>
                        </template>
                    </resource-selector>
                    <span class="text-danger">{{ errors.first("person") }}</span>
                </div>
                <!-- /Person -->
                <!-- Organization -->
                <div class="form-group col-md-6">
                    <label for="organization">Organization</label>
                    <resource-selector
                        v-model="formData.organization"
                        :class="{ invalid: errors.first('organization') }"
                        :resource="formData.organization"
                        :searchable-by="['name']"
                        :show-add-resource="true"
                        :show-no-options="false"
                        :show-no-results="false"
                        name="organization"
                        placeholder="Search for an organization"
                        resource-id="id"
                        resource-label="organization"
                        resource-url="/organizations"
                        @add-resource="addOrganization"
                    />
                    <span class="text-danger">{{ errors.first("organization") }}</span>
                </div>
                <!-- /Organization -->
            </template>
            <!-- title -->
            <div class="form-group col-md-6">
                <div class="control">
                    <label for="middleName">Title</label>
                    <input
                        v-model="formData.title"
                        v-validate="'required'"
                        class="form-control"
                        name="title"
                        placeholder="John Doe's lead"
                        type="text"
                        @keyup="checkTitleChange()"
                    >
                    <span class="text-danger">{{ errors.first("title") }}</span>
                </div>
            </div>
            <!-- /title -->
            <!-- source -->
            <div class="form-group col-md-6">
                <div class="control">
                    <label for="source">Source</label>
                    <multiselect
                        v-model="formData.source"
                        v-validate="'required'"
                        :internal-search="true"
                        :options="sources"
                        :searchable="true"
                        :show-labels="false"
                        label="name"
                        name="source"
                        placeholder="Select a source"
                        track-by="id"
                    />
                    <span class="text-danger">{{ errors.first("source") }}</span>
                </div>
            </div>
            <!-- /source -->
            <!-- type -->
            <div class="form-group col-md-6">
                <div class="control">
                    <label for="type">Type</label>
                    <multiselect
                        v-model="formData.type"
                        v-validate="'required'"
                        :internal-search="true"
                        :options="types"
                        :searchable="true"
                        :show-labels="false"
                        label="name"
                        name="type"
                        placeholder="Select a type"
                        track-by="id"
                    />
                    <span class="text-danger">{{ errors.first("type") }}</span>
                </div>
            </div>
            <!-- /type -->
            <!-- is visiting -->
            <div class="form-group col-md-6 mt-2 is-visiting">
                <label>
                    <input
                        v-model="formData.is_currently_visiting"
                        :disabled="isCurrentlyVisitingOn || isWalkInType && !editMode"
                        type="checkbox"
                        @click="isCurrentlyVisitingClicked = !isCurrentlyVisitingClicked"
                    >
                    Is currently visiting
                </label>
            </div>
            <!-- End is visiting -->
            <!-- leads owner -->
            <div class="form-group col-md-6">
                <label for="owner">Lead Owner</label>
                <resource-selector
                    id="leadsOwner"
                    v-model="formData.owner"
                    v-validate="'required'"
                    :class="{invalid: errors.first('owner')}"
                    :custom-label="userLabel"
                    :disabled="disabled || !canEditLeadOwner"
                    :resource="formData.owner"
                    :searchable-by="['firstname','lastname', 'displayname']"
                    name="owner"
                    placeholder="Select a Lead Owner"
                    resource-id="id"
                    resource-url="/users?limit=250&filters=(user_active:1)&text="
                />
                <span class="text-danger">{{ errors.first("owner") }}</span>
            </div>
            <!-- / leads owner -->
            <template v-if="featureFlags.leads_pipeline_stages">
                <!-- Pipeline -->
                <div class="form-group col-md-6">
                    <label for="pipeline">Pipeline</label>
                    <multiselect
                        v-model="formData.pipeline"
                        v-validate="'required'"
                        :allow-empty="false"
                        :options="pipelines"
                        :searchable="true"
                        :show-labels="false"
                        label="name"
                        name="pipeline"
                        placeholder="Select a pipeline"
                        track-by="id"
                        @input="fetchPipelineStages()"
                    />
                    <span class="text-danger">{{ errors.first("pipeline") }}</span>
                </div>
                <!-- /Pipeline -->
                <!-- Pipeline Stage -->
                <div class="form-group col-md-6">
                    <label for="stage">Stage</label>
                    <multiselect
                        v-model="formData.stage"
                        v-validate="'required'"
                        :allow-empty="false"
                        :options="stages"
                        :searchable="true"
                        :show-labels="false"
                        label="name"
                        name="stage"
                        placeholder="Select a stage"
                        track-by="id"
                    />
                    <span class="text-danger">{{ errors.first("stage") }}</span>
                </div>
                <!-- /Pipeline Stage -->
            </template>
            <!-- branch -->
            <div class="form-group col-md-6">
                <label for="branch">Branch</label>
                <multiselect
                    v-model="formData.branch"
                    v-validate="'required'"
                    :allow-empty="false"
                    :options="branches"
                    :searchable="true"
                    :show-labels="false"
                    label="name"
                    name="branch"
                    placeholder="Select a branch"
                    track-by="id"
                />
            </div>
            <!-- / branch  v-validate="'required'" -->
            <!-- manager followers -->
            <!-- <template v-if="featureFlags.leads_managers">
                <div v-if="!editMode" class="form-group col-md-6">
                    <label for="managersFollowers">Manager(s)</label>
                    <resource-selector
                        id="managersFollowers"
                        v-model="formData.managersFollowers"
                        :class="{invalid: errors.first('managersFollowers')}"
                        :custom-label="userLabel"
                        :multiple="true"
                        :resource="formData.managersFollowers"
                        :resource-url="`/users/${ROLES.ADMINS}/roles`"
                        :searchable-by="['firstname','lastname']"
                        name="managersFollowers"
                        placeholder="Select at least one manager"
                        resource-id="id"
                    />
                    <span class="text-danger">{{ errors.first("managersFollowers") }}</span>
                </div>
            </template> -->
            <!-- / manager followers -->
            <div class="col-md-6 offset-md-6 mt-5">
                <div class="actions d-flex justify-content-end">
                    <button class="btn btn-danger" type="button" @click.stop="$emit('cancel')">
                        Cancel
                    </button>
                    <button class="btn btn-primary">
                        {{ createButtonText }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _cloneDeep from "lodash/cloneDeep";
import { ROLES } from "@/config/constants";
import ResourceSelector from "@c/resource-selector";

export default {
    components: {
        ResourceSelector
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        recordData: {
            type: Object,
            default() {
                return {}
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modal: {
            type: Boolean,
            default: false
        },
        changeOwnerMode: {
            type: Boolean,
            default: false
        },
        changeStageMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            endpoint: "/leads",
            isOpenStage: false,
            formData: {
                owner: {},
                organization: null,
                source: null,
                type: null,
                pipeline: null,
                person: null,
                stage: null,
                title: "",
                managersFollowers: [],
                is_currently_visiting: false
            },
            isCurrentlyVisitingOn: false,
            isCurrentlyVisitingClicked: false,
            isManualTitle: false,
            newOrganization: "",
            newPerson: "",
            pipelines: [],
            sources: [],
            types: [],
            stages: [],
            searchableByProcess: {
                people: {
                    phone_clean: (value) => {
                        return value.replace(/[^\d]/g, "");
                    }
                }
            },
            ROLES
        }
    },
    computed: {
        ...mapState({
            user: state => state.User.data,
            branches: state => {
                return state.Company.list.reduce((branches, company) => {
                    branches.push(...company.branches);
                    return branches;
                }, [])
            }
        }),
        ...mapGetters({
            featureFlags: "Application/featureFlags"
        }),
        defaultBranch() {
            return this.user.default_company_branch && this.getBranch(this.user.default_company_branch);
        },
        createButtonText() {
            return this.formData.id ? "Save Changes" : "Create";
        },
        canEditLeadOwner() {
            const canEditLeadOwner = this.$can("allow-edit-lead-owner", "leads");
            const isSameUser = this.formData.users_id == this.user.id;
            return canEditLeadOwner || isSameUser;
        },
        computedTitle() {
            const person = this.formData.person?.name || this.newPerson;
            const organization = this.formData.organization?.name || this.newOrganization;
            const leadTitlePrefix = person || organization || "";
            const leadTitle = leadTitlePrefix ? `${leadTitlePrefix} Lead` : "";

            return leadTitle;
        },
        isWalkInType() {
            return this.formData.type && this.formData.type.name == "Walk-in";
        }
    },
    watch: {
        recordData: {
            handler(data) {
                if (data.id) {
                    this.isCurrentlyVisitingOn = data.is_chrono_running;
                    const formData = _cloneDeep(data);
                    formData.branch = this.getBranch(data.companies_branches_id);
                    formData.is_currently_visiting = formData.is_chrono_running;
                    this.formData = formData;
                } else {
                    this.clearForm();
                }
            },
            immediate: true,
            deep: true
        },
        sources() {
            this.formData.source = this.sources.find(source => source.id == this.recordData.leads_sources_id) || null;
        },
        types() {
            this.formData.type = this.types.find(type => type.id == this.recordData.leads_types_id) || null;
        },
        isWalkInType() {
            if (!this.isCurrentlyVisitingClicked) {
                this.formData.is_currently_visiting = this.isWalkInType;
            }
        },
        computedTitle() {
            if (!this.isManualTitle) {
                this.formData.title = this.computedTitle;
            }
        }
    },
    created() {
        this.$validator.localize("en", {
            custom: {
                person: {
                    required: "A person is required"
                },
                owner: {
                    required: "The lead owner is required"
                },
                // managersFollowers: {
                //     required: "A manager is required"
                // },
                pipeline: {
                    required: "A pipeline is required"
                },
                stage: {
                    required: "A stage is required"
                },
                source: {
                    required: "A source is required"
                },
                type: {
                    required: "A type is required"
                }
            }
        });

        this.initialize();
    },
    methods: {
        openStage() {
            this.isOpenStage = true;
        },
        closeStage() {
            this.isOpenStage = false;
        },
        async initialize() {
            await this.fetchPipelines();
            await this.fetchPipelineStages();
            await this.fetchSources();
            await this.fetchTypes();
        },
        addOrganization(name) {
            this.newOrganization = name;
        },
        addPerson(name) {
            this.newPerson = name;
            this.$refs.personResource.toggle();
        },
        createLead() {
            let method = "POST";
            let url = this.endpoint;

            const formData = {
                id: this.formData.id,
                title: this.formData.title,
                pipeline_id: this.formData.pipeline.id,
                pipeline_stage_id: this.formData.stage.id
            };

            formData.leads_owner_id = this.formData.owner.id;
            this.formData.organization && (formData.organization_id = this.formData.organization.id);
            (this.formData.person || this.formData.people) && (formData.people_id = (this.formData?.person?.id || this.formData?.people_id));
            this.formData.branch && (formData.companies_id = this.formData.branch.companies_id);
            this.formData.branch && (formData.companies_branches_id = this.formData.branch.id);

            if (this.formData.source) {
                formData.leads_sources_id = this.formData.source.id;
            }

            if (this.formData.type) {
                formData.leads_types_id = this.formData.type.id;
            }

            if (formData.id) {
                method = "PUT"
                url += `/${formData.id}`
            }

            if (this.formData.is_currently_visiting) {
                formData.is_chrono_running = true;
                formData.leads_visits_count += 1;
                formData.chrono_start_date = (new Date()).toISOString();
            } else {
                formData.is_chrono_running = false;
                formData.chrono_start_date = null;
            }

            if (!this.isLoading) {
                this.isLoading = true;
                axios({
                    url: url,
                    data: formData,
                    method: method
                }).then(async({ data }) => {
                    // if (this.featureFlags.leads_managers && !this.editMode) {
                    //     await this.addManagers(data.id);
                    // }
                    this.onSuccess(data);
                }).catch((error) => {
                    this.$notify({
                        title: "Error",
                        text: error.response ? error.response.data.errors.message : "Error",
                        type: "error"
                    });
                    this.isLoading = false;
                    return false;
                }).finally((result) => {
                    return result;
                });
            }
        },
        addManagers(leadId) {
            const managersList = this.formData.managersFollowers.map(manager => manager.id);

            return axios({
                url: `/leads/${leadId}/access`,
                method: "POST",
                data: {
                    users_id: managersList
                }
            }).catch((error) => {
                this.$notify({
                    title: "Error",
                    text: error.response ? error.response.data.errors.message : "Error adding follower(s).",
                    type: "error"
                });
            });
        },
        createOrganization() {
            if (this.newOrganization === "") {
                return;
            }

            return axios({
                url: "/organizations",
                method: "POST",
                data: {
                    name: this.newOrganization
                }
            });
        },
        async createPerson() {
            if (this.newPerson === "") {
                return;
            }

            return axios({
                url: "/peoples",
                method: "POST",
                data: {
                    name: this.newPerson
                }
            });
        },
        checkTitleChange() {
            if (this.formData.title !== this.computedTitle && this.formData.title !== "") {
                this.isManualTitle = true;
            }

            if (this.formData.title === "") {
                this.isManualTitle = false;
            }
        },
        processCreatedOrganization(organization) {
            if (organization.status === "rejected" || typeof organization.value === "undefined") {
                return;
            }

            this.$set(this.formData, "organization", organization.value.data);
            this.newOrganization = "";
        },
        processCreatedPerson(person) {
            if (person.status === "rejected" || typeof person.value === "undefined") {
                return;
            }

            this.$set(this.formData, "person", person.value.data);
            this.newPerson = "";
        },
        userLabel(user) {
            return user ? `${user.firstname || ""} ${user.lastname || ""}` : ""
        },
        clearForm() {
            this.formData = {
                owner: this.user,
                branch: this.defaultBranch,
                organization: null,
                person: null,
                source: null,
                type: null,
                title: "",
                managersFollowers: null
            }
        },
        async fetchPipelines() {
            await axios({
                url: "/pipelines?q=(modules_rs.slug:leads)"
            }).then(({ data }) => {
                this.pipelines = data;
                let pipeline = data[0];

                if (this.formData.pipeline_id) {
                    const currentPipeline = data.find(item => item.id == this.formData.pipeline_id);
                    pipeline = currentPipeline || pipeline;
                } else {
                    const defaultPipeline = data.find(item => Boolean(parseInt(item.is_default)));
                    pipeline = defaultPipeline || pipeline;
                }

                this.$set(this.formData, "pipeline", pipeline);
            });
        },
        async fetchPipelineStages() {
            if (!this.formData.pipeline) {
                return;
            }

            await axios({
                url: `/pipelines/${this.formData.pipeline.id}/stages`
            }).then(({ data }) => {
                this.stages = data;
                const stage = data.find(item => item.id == this.formData.pipeline_stage_id) || data[0];

                this.$set(this.formData, "stage", stage);
            });
        },
        async fetchSources() {
            await axios({
                url: "/leads-sources"
            }).then(({ data }) => {
                this.sources = data;
            });
        },
        async fetchTypes() {
            await axios({
                url: "/leads-types"
            }).then(({ data }) => {
                this.types = data;
            });
        },
        onSuccess(record) {
            let title = "Created";
            let text = "A new Lead has been created"

            if (this.formData.id) {
                title = "Updated";
                text = "The changes has been saved"
            }

            this.$notify({
                title,
                text,
                type: "success"
            });

            this.$emit("saved", record);
            this.isLoading = false;

            if (this.changeOwnerMode || this.changeStageMode) {
                this.$modal.hide('Change-owner');
                this.$modal.hide('Change-stage');
                return;
            } else {
                this.$router.push({
                    name: "edit-resource",
                    params: {
                        resource: "leads",
                        id: record.id
                    }
                });
            }
        },
        async checkPersonActiveLeads() {
            if (this.formData.person?.id) {
                return axios({
                    url: "/leads",
                    params: {
                        q: `(people_id:${this.formData.person.id},leads_status_rs.name:active%,types.name!service%)`
                    }
                });
            }

            return { data: [] };
        },
        async beforeSave() {
            const isValid = await this.$validator.validateAll();

            if (!isValid) {
                this.focusFirstError();
                return;
            }

            this.$modal.hide("Create-Leads");

            const { data: leadList } = await this.checkPersonActiveLeads();

            if (leadList.length) {
                this.promptActiveLeads(leadList[0]);
            } else {
                this.save();
            }
        },
        async save() {
            Promise.allSettled([
                this.createOrganization(),
                this.createPerson()
            ]).then((results) => {
                this.processCreatedOrganization(results[0]);
                this.processCreatedPerson(results[1]);

                const createRejected = results.some(result => result.status === "rejected");

                if (!createRejected) {
                    this.createLead();
                }
            });
        },
        promptActiveLeads(lead) {
            this.$modal.show("basic-modal", {
                title: "Open Lead Found",
                message: "This Person already has an open Lead.",
                buttons: [{
                    title: "Continue",
                    class: "btn-danger",
                    handler: () => {
                        this.$modal.hide("basic-modal");
                        this.save();
                    }
                }, {
                    title: "Open Lead",
                    class: "btn-primary",
                    handler: () => {
                        this.$modal.hide("basic-modal");
                        this.$router.push({
                            name: "edit-resource",
                            params: {
                                resource: "leads",
                                id: lead.id
                            }
                        });
                    }
                }],
                draggable: false,
                showClose: false
            });
        },
        getBranch(branchId) {
            return this.branches.find(branch => branch.id == branchId);
        }
    }
}
</script>
<style lang="scss" scoped>
.form-group label {
    text-transform: initial !important;
}

.is-visiting {
    display: flex;
    align-items: center;
    user-select: none;

    label {
        cursor: pointer;
        margin-bottom: 0;
        margin-top: 24px;
    }
}
</style>

<style lang="scss">
.multiselect.person-select {
    .multiselect__element .multiselect__option {
        flex-direction: column;
        align-items: start;
    }
}
</style>
