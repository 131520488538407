<template>
    <div class="stages-box">
        <div
            v-for="(stage, index) in stages"
            :key="`stage-${stage.id}`"
            class="stages-container"
            :class="{ 'stages-container-margin': stages.length > 5 }"
            :style="`height: 100%; ${index > 0 ? 'margin-left: -32px;' : ''}`"
            @click="$emit('selected', stage)"
        >
            <el-tooltip effect="dark" :content="stage.name" placement="bottom">
                <pipeline-start
                    v-if="index === 0"
                    :active="stage.weight === currentStage.weight"
                    :pass="stage.weight < currentStage.weight"
                    :height="22"
                />
                <pipeline-middle
                    v-if="index > 0 && index < stages.length"
                    :active="stage.weight === currentStage.weight"
                    :pass="stage.weight < currentStage.weight"
                    :height="25"
                />
                <pipeline-end
                    v-if="index === stages.length - 1"
                    :active="stage.weight === currentStage.weight"
                    :pass="stage.weight < currentStage.weight"
                    :height="25"
                />
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import PipelineEnd from "./stages-box-pipes/pipeline-end.vue";
import PipelineStart from "./stages-box-pipes/pipeline-start.vue";
import PipelineMiddle from "./stages-box-pipes/pipeline-middle.vue";

export default {
    components: {
        PipelineEnd,
        PipelineStart,
        PipelineMiddle
    },
    props: {
        currentStage: {
            type: Object,
            default() {
                return {};
            }
        },
        stages: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>

<style lang="scss">
.stages-box {
    display: flex;
    flex-direction: row;
    // width: auto;
    margin-bottom: 10px;
    margin-left: -5px;
    // position: relative;
    // justify-content: center;
    align-items: flex-start;
    // overflow-x: scroll;

    .stages-container {
        //     height: 100%;
        //     width: 100%;
        //     display: flex;
        //     margin-bottom: 5px;
        //     margin-right: 3px;

        svg {
            height: 10px;
            all: unset;
            cursor: pointer;
        }
    }

    // .stages-container-margin:first-child {
    //     margin-left: 129px;
    // }
}
</style>

<style lang="scss" scoped>
*::-webkit-scrollbar {
    *::-webkit-scrollbar {
        width: 8px;
        background-color: #f9fafb00;
    }
    *::-webkit-scrollbar-thumb {
        background-color: var(--base-color);
        border-radius: 5px;
    }
    *::-webkit-scrollbar-track {
        background-color: #f9fafb00;
        width: 15px;
    }
    width: 8px;
    background-color: #f9fafb00;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--base-color);
    border-radius: 5px;
}
*::-webkit-scrollbar-track {
    background-color: #f9fafb00;
    width: 15px;
}
</style>
