import _keyBy from "lodash/keyBy";
import _groupBy from "lodash/groupBy";
import PeoplesForm from "../peoples/form";

export default {
    components: {
        PeoplesForm
    },
    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            contacts: {},
            contactsTypes: null,
            personId: null,
            person: null
        }
    },
    methods: {
        async fetchContacts() {
            await axios({
                url: `/peoples/${this.personId}/contacts`
            }).then(({ data }) => {
                this.$set(this.person, "contacts", data);
                if (data.length != 0) {
                    this.contacts = _groupBy(data, "contacts_types_id");
                }

                // This will set a field to any empty contact types.
                this.setContacts();
            });
        },
        async fetchContactsTypes() {
            await axios({
                url: "/contacts-types"
            }).then(({ data }) => {
                this.contactsTypes = _keyBy(data, type => type.id);
            });
        },
        async fetchPerson() {
            await axios({
                url: `/peoples/${this.personId}`
            }).then(({ data }) => {
                this.person = data;
            });
        },
        async setContacts() {
            Object.keys(this.contactsTypes).forEach(key => {
                const contactObj = {
                    contacts_types_id: key,
                    value: ""
                };

                if (!this.contacts[key]) {
                    this.$set(this.contacts, key, [contactObj]);
                }
            });
        }
    }
}
