<template>
    <validation-observer
        ref="observer"
        :class="{ card: isStandalone && !modal }"
        class="organization-form"
        tag="form"
        @submit.prevent="save()"
    >
        <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
            slim
        >
            <div>
                <div
                    :class="{ 'form-group': isStandalone }"
                    class="row"
                >
                    <div v-if="!modal" class="col-3 form-label">
                        <label for="name" class="control-label">Name</label>
                    </div>
                    <div class="col-9">
                        <input
                            id="name"
                            v-model="organizationData.name"
                            type="text"
                            class="form-control"
                            name="name"
                            style="width: 440px"
                            placeholder="Name"
                        >
                    </div>
                </div>
                <div v-if="errors[0]" class="row">
                    <div :class="{ 'offset-3': !isStandalone }" class="col-9 text-danger">
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </validation-provider>
        <div>
            <div
                :class="{ 'form-group': isStandalone }"
                class="row"
            >
                <div v-if="!modal" class="col-3 form-label">
                    <label for="address" class="control-label">Address</label>
                </div>
                <div class="col-9">
                    <input
                        id="address"
                        v-model="organizationData.address"
                        type="text"
                        class="form-control"
                        name="address"
                        style="width: 440px"
                        placeholder="Address"
                    >
                </div>
            </div>
            <div class="row organization-form--footer" :class="{ 'pt-2': modal }">
                <div :class="footerClass">
                    <button :class="cancelButtonClass" type="button" @click="$emit('cancel')">
                        Cancel
                    </button>
                    <button :class="saveButtonClass" type="submit">
                        {{ saveText }}
                    </button>
                </div>
            </div>
        </div>
    </validation-observer>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    name: "OrganizationForm",
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: {
        mode: {
            type: String,
            default: "inline"
        },
        organization: {
            type: Object,
            required: true
        },
        modal: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            isEditing: typeof this.organization.id !== "undefined",
            organizationData: _cloneDeep(this.organization)
        };
    },
    computed: {
        cancelButtonClass() {
            return this.isStandalone ? "btn btn-danger mr-3" : "btn btn-danger mr-2 btn-normal";
        },
        footerClass() {
            return this.isStandalone ? "col-md-6 offset-md-6 d-flex justify-content-end" : "col-12";
        },
        isStandalone() {
            return this.mode === "standalone";
        },
        saveButtonClass() {
            return this.isStandalone ? "btn btn-primary" : "btn btn-primary btn-normal";
        },
        saveText() {
            return this.isStandalone && !this.isEditing ? "Create" : "Save All";
        }
    },
    methods: {
        async save() {
            const isFormValid = await this.$refs.observer.validate();

            if (!isFormValid) {
                return;
            }

            let url = `/organizations/${this.organizationData.id}`;
            let method = "PUT";

            if (!this.isEditing && this.isStandalone) {
                url = "/organizations";
                method = "POST";
            }

            axios({
                url,
                method,
                data: this.organizationData
            }).then(() => {
                this.$emit("save", this.organizationData);
            }).catch(() => {
                this.$notify({
                    title: "Error",
                    text: "Something went wrong when trying to update organization.",
                    type: "error"
                });
            });
        }
    }
}
</script>
