<template>
    <div class="updates-form">
        <div class="updates-form__form">
            <activity-widget
                :reset-widget-send="true"
                :reset-widget-discard="true"
                :lead-id="Number($route.params.id)"
                @save="send"
            />
        </div>
    </div>
</template>

<script>
import ActivityWidget from "@c/feed/activity-widget";

export default {
    name: "ActivityForm",
    components: {
        ActivityWidget
    },
    props: {
        endpoint: {
            type: String,
            default: ""
        }
    },
    methods: {
        send(data) {
            const payload = {
                title: data.title,
                start_date: data.date_start,
                end_date: data.date_end,
                lead_id: Number(this.$route.params.id),
                description: data?.description || "",
                type: {
                    ...(data?.activity_type ?? {})
                },
                status: {
                    ...(data?.complete
                        ? { id: 3, name: "Complete" }
                        : { id: 1, name: "Pending" })
                }
            };

            axios({
                method: "POST",
                url: this.endpoint,
                data: payload
            })
                .then((response) => {
                    this.$emit("activity-saved", response.data);
                    this.$notify({
                        title: "Success",
                        text: "New activity added",
                        type: "success"
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
};
</script>

<style lang="scss">
.updates-form {
    .feed-form:not(.form) {
        .quill-editor {
            border: 1px solid #ececec;
            border-radius: 6px;
            min-height: 180px;
        }
        .content-error {
            padding-left: 0;
        }
    }

    .feed-toolbar {
        border: none;
    }

    .ql-editor.ql-blank::before {
        color: #9b9b9b;
        letter-spacing: 0.1px;
        font-family: Inter, sans-serif;
    }

    .ql-container {
        font-size: 16px;
        font-family: Inter, sans-serif;
    }
}
</style>

<style lang="scss" scoped>
.updates-form {
    padding: 0 50px;

    &__show-form {
        padding: 20px 0;
        cursor: text;
        letter-spacing: 0px;
        color: #9b9b9b;
        font-size: 14px;
        font-weight: 500;
    }

    &__form {
        padding-top: 30px;
    }
}
</style>
