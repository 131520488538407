<template>
    <div class="contact">
        <div class="follower-item d-flex pl-2">
            <div class="data-label text-right mr-2">
                <skeleton width="14px" height="16px" class="mr-0" />
            </div>
            <div class="d-flex justify-between w-100">
                <skeleton width="130px" height="15px" class="mr-2" />
            </div>
        </div>
    </div>
</template>

<script>
import { Skeleton } from "vue-loading-skeleton"

export default {
    name: "MembersBoxItemSkeleton",
    components: {
        Skeleton
    }
}
</script>
