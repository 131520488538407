<template>
    <multiselect
        :id="uuid"
        ref="multiselect"
        v-model="localResource"
        :name="name"
        :label="label"
        :track-by="resourceId"
        :placeholder="placeholder"
        open-direction="bottom"
        :multiple="multiple"
        :group-values="groupValues"
        :group-label="group"
        :options="options"
        :searchable="true"
        :loading="isLoading"
        :options-limit="300"
        :disabled="disabled"
        :limit="300"
        :internal-search="!searchableBy.length"
        :custom-label="customLabel"
        :show-labels="false"
        :show-no-results="showNoResults"
        @close="setLoaded"
        @open="repositionDropdown"
        @search-change="searchResource"
        @input="emitId"
    >
        <span v-if="showNoOptions" slot="noOptions">
            {{ !isLoaded ? 'Type to search' : 'List is empty.' }}
        </span>
        <slot
            slot="option"
            slot-scope="props"
            name="option"
            :option="props.option"
        >
            <span>{{ $refs.multiselect.getOptionLabel(props.option) }}</span>
        </slot>
        <li
            v-if="computedShowAddResource"
            slot="afterList"
            class="multiselect__element"
            @click="addResource()"
        >
            <span class="multiselect__option">
                Add "{{ searchValue }}" as a new {{ resourceLabel }}
            </span>
        </li>
    </multiselect>
</template>

<script>
import _debounce from "lodash/debounce";
import uuid from "uuid/v4";

export default {
    inheritAttrs: false,
    props: {
        resourceUrl: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "name"
        },
        // eslint-disable-next-line vue/require-default-prop
        customLabel: {
            type: Function,
            required: false
        },
        resourceId: {
            type: String,
            default: ""
        },
        group: {
            type: String,
            default: ""
        },
        groupValues: {
            type: String,
            default: ""
        },
        resource: {
            type: [Object, Array],
            default: null
        },
        resourceLabel: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        processResponse: {
            type: Function,
            default: null
        },
        shouldEmitId: {
            type: Boolean,
            default: false
        },
        showAddResource: {
            type: Boolean,
            default: false
        },
        showNoOptions: {
            type: Boolean,
            default: true
        },
        showNoResults: {
            type: Boolean,
            default: true
        },
        filters: {
            type: Object,
            default() {
                return {}
            }
        },
        initialSearch: {
            type: Boolean,
            default: false
        },
        searchableBy: {
            type: Array,
            default() {
                return []
            }
        },
        searchableByProcess: {
            type: Object,
            default() {
                return {}
            }
        },
        cacheItems: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            uuid: uuid(),
            isLoading: false,
            options: [],
            localResource: null,
            isLoaded: false,
            newResource: "",
            searchValue: "",
            cachedItems: [],
            isClosing: false
        };
    },
    computed: {
        computedShowAddResource() {
            return this.showAddResource && this.searchValue.length >= 2 && this.searchValue !== this.newResource;
        }
    },
    watch: {
        resource: {
            handler(resource) {
                this.localResource = resource;
                this.newResource = "";
            },
            deep: true,
            immediate: true
        },
        resourceUrl() {
            this.searchResource("", true);
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        async initialize() {
            if (this.cacheItems) {
                const response = await this.request(this.resourceUrl, "");

                this.cachedItems = response;
                this.options = this.processResponse ? this.processResponse(this.cachedItems) : this.cachedItems;
            }
        },
        addResource() {
            this.newResource = this.searchValue;
            this.localResource = {
                [this.resourceId]: 0,
                [this.label]: this.newResource
            };
            this.$emit("add-resource", this.newResource);
        },
        searchResource(value = "", forceGet) {
            if (this.isClosing) {
                this.isClosing = false;
                this.options = this.cachedItems;
                return;
            }


            if (value.length >= 2 || forceGet) {
                this.searchValue = value;
                this.fetchSearch(value, forceGet);
            } else {
                this.searchValue = "";
                this.options = [];
            }
        },
        fetchSearch: _debounce(async function search(value = "", forceGet) {
            this.isLoading = true;
            const url = this.resourceUrl;
            const hasSearchFields = this.searchableBy.length
            const requestMethod = hasSearchFields ? this.request : this.getAllResources;
            if (hasSearchFields || !this.options.length || forceGet) {
                const response = await requestMethod(url, value)
                this.options = this.processResponse ? this.processResponse(response) : response
            }
            this.isLoaded = true;
            this.isLoading = false;
        }, 300),
        async request(url, searchText) {
            if (url && url[0] != "/") {
                url = `/${url}`
            }

            const connector = url.includes("?") ? "&" : "?";
            const params = this.getParams(searchText)

            const resources = await axios({
                url: `${url}${connector}`,
                params
            })
                .then(({ data }) => data || [])
            return resources;
        },
        getParams(searchText, separator = "%") {
            const params = {}
            let stringParams = "";
            let filterParams = "";

            if (searchText.length) {
                if (this.searchableBy.length) {
                    stringParams = this.searchableBy.map(field => {
                        let prepareText = searchText;
                        if (field in this.searchableByProcess) {
                            prepareText = this.searchableByProcess[field](prepareText);
                        }
                        prepareText = escape(prepareText);
                        return `${field}:${separator}${prepareText}${separator}`;
                    }).join(";");
                }
            }

            if (Object.keys(this.filters).length) {
                filterParams = Object.entries(this.filters).map(filter => `${filter[0]}:${filter[1]}`).join(",")
                stringParams = [stringParams, filterParams].filter(item => item).join(",")
            }

            if (stringParams) {
                params.q = `(${stringParams})`;
            }

            return params;
        },
        setLoaded() {
            this.isClosing = true;

            if (this.searchableBy.length || !this.options.length) {
                this.isLoaded = false;
            }
        },
        emitId(resource) {
            const resourceId = resource ? resource[this.resourceId] : resource;
            this.newResource = "";
            this.$emit("add-resource", this.newResource);
            this.$emit("input", this.shouldEmitId ? resourceId : resource);
            this.$emit("native-input", resource);
        },
        repositionDropdown(id) {
            const el = document.getElementById(id);
            const parent = el.closest(".multiselect");
            const content = parent.querySelector(".multiselect__content-wrapper");
            const { top, height, left } = parent.getBoundingClientRect();

            content.style.width = `${parent.clientWidth}px`;
            content.style.position = "fixed";
            content.style.bottom = "auto";
            content.style.top = `${top + height}px`;
            content.style.left = `${left}px`;

            if (this.initialSearch) {
                this.searchResource("", true);
            }
        },
        toggle() {
            this.$refs.multiselect.toggle();
        }
    }
};
</script>

<style lang="scss">
.multiselect__ {
    &after-list {
        padding: 12px;
    }
}
</style>
