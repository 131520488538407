<template>
    <ul class="list-group mb-4">
        <li class="list-group-item">
            <span class="font-weight-bold">Sexo</span>: {{ userData.sex_gender }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Nombre(s):</span> {{ userData.name }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">1er Apellido:</span> {{ userData.lastname1 }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">2do Apellido:</span> {{ userData.lastname2 }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Cédula o Pasaporte del solicitante:</span> {{ userData.identification_type }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Numero del documento:</span> {{ userData.identification_card }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Teléfono (celular) del solicitante:</span> {{ userData.phone }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Correo Electrónico del solicitante:</span> {{ userData.email }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">¿Qué vinculo tiene el solicitante con la propiedad?:</span> {{ userData.leads_owner }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">¿En cual banco esta solicitando el prestamo?:</span> {{ userData.leads_bank ? "Si" : "No" }}
        </li>
        <li v-if="userData.loan_with_property" class="list-group-item">
            <span class="font-weight-bold">¿En cual banco esta solicitando el prestamo?:</span> {{ userData.leads_bank }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">¿Que tipo de propiedad es?:</span> {{ userData.description }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">¿Cuál es la dirección de la propiedad que solicita?:</span> {{ userData.street }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">¿Cuál es la dirección de la propiedad que solicita?:</span> {{ userData.street_number }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Barrio o Residencial:</span> {{ userData.neighborhoodes }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Provincia:</span> {{ userData.province }}
        </li>
        <li class="list-group-item">
            <span class="font-weight-bold">Municipio:</span> {{ userData.municipality }}
        </li>
        <li v-if="userData.location" class="list-group-item">
            <span class="font-weight-bold">Ubicación:</span> {{ userData.location_address }}<br>
            <a target="__blank" :href="`https://www.google.com/maps/search/?api=1&query=${JSON.parse(userData.location)['lat']},${JSON.parse(userData.location)['lng']}`">Ir a la ubicación</a>
        </li>
        <template v-if="userData.files">
            <li
                v-for="images in userData.files"
                :key="images.filesystem_id"
                class="list-group-item"
            >
                <img class="document-img" :src="images.url" :alt="images.name">
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    name: "RequestInfo",
    props: {
        requestInfo: {
            type: [Object, Array],
            default: () => {}
        }
    },
    data() {
        return {
            userData: {
                sex_gender: "",
                name: "",
                lastname1: "",
                lastname2: "",
                email: "",
                identification_type: "cedula",
                identification_card: "",
                country_code: "+1",
                phone: "",
                leads_owner: "",
                loan_with_property: "",
                leads_bank: "",
                description: {
                    property_type: "",
                    property_meters: ""
                },
                street: "",
                street_number: "", 
                neighborhoodes: "",
                province: "",
                municipality: "",
                reference: "",
                neighbors_left: "Izquierda",
                neighbors_right: "Derecha",
                neighbors_behind: "Atrás",
                neighbors_left_reference: "",
                neighbors_right_reference: "",
                neighbors_behind_reference: "",
                location: null,
                location_address: "",
                files: []
            }
        }
    },
    watch: {
        requestInfo() {
            this.userData = Object.assign({}, this.userData, this.requestInfo);
        }
    }
}
</script>


<style lang="scss" scoped>
    .document-img {
        width: 400px;
    }
</style>
