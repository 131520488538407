<template>
    <span class="message-text" :class="{ 'my-message': isMy }">
        {{ message.body }}
    </span>
</template>

<script>
export default {
    name: "MessageComponent",
    props: {
        isMy: {
            type: Boolean
        },
        message: {
            type: Object,
            required: true
        }
    }
};
</script>

<style>
.message-text {
    box-sizing: border-box;
    border: 1px solid #ececec;
    padding: 0.5rem 1rem;
    margin: 1rem;
    background: #ffffff;
    border-radius: 0.5rem;
    min-height: fit-content;
    width: 50%;
    max-width: 40%;
    word-break: keep-all;
    box-shadow: -2px 3px 11px -10px rgba(0, 0, 0, 1);
    -webkit-box-shadow: -2px 3px 11px -10px rgba(0, 0, 0, 1);
    -moz-box-shadow: -2px 3px 11px -10px rgba(0, 0, 0, 1);
}

.my-message {
    margin: 1rem 1rem 1rem auto;
    background: #eeeeee;
    color: black;
    box-shadow: none;
    border: none;
    text-align: right;
}
</style>
