<template>
    <div class="message-head">
        <div class="message-head-img">
            <img
                src="https://cdn.salesassist.io/files_dev/app/storage/cache/data/ekq3g7.jpeg"
                alt="img"
            >

            <span class="ml-3"> {{ person.name }} </span>
        </div>

        <div class="menu">
            <span class="mr-4 text-capitalize">
                {{ connection }}
            </span>
            <!-- <i class="fa-solid fa-ellipsis" /> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        connection: {
            type: String,
            required: true
        },
        person: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.message-head {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ececec;
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: center;
    align-items: center;
}

.message-head-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #717171;
}

.menu {
    cursor: pointer;
}

img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
</style>
