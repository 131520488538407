<template>
    <svg
        class="app-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16.137"
        height="16.137"
    >
        <path d="M8.068 16.138a7.744 7.744 0 003.121-.635 8.267 8.267 0 004.316-4.316 7.758 7.758 0 00.631-3.117 7.758 7.758 0 00-.631-3.117A8.271 8.271 0 0011.18.632a7.995 7.995 0 00-6.226 0 8.271 8.271 0 00-4.323 4.32 7.758 7.758 0 00-.634 3.117 7.715 7.715 0 00.635 3.117 8.295 8.295 0 004.321 4.316 7.724 7.724 0 003.115.636zm-1.436-3.612a.629.629 0 01-.444-.174.576.576 0 01-.187-.44.584.584 0 01.187-.432.613.613 0 01.444-.183h.979V7.778h-.838a.633.633 0 01-.448-.17.611.611 0 010-.872.617.617 0 01.448-.178h1.544a.6.6 0 01.5.216.885.885 0 01.17.564v3.96h.946a.609.609 0 01.448.183.59.59 0 01.183.432.582.582 0 01-.183.44.625.625 0 01-.448.174zM8.01 5.263a1.053 1.053 0 01-.78-.32 1.123 1.123 0 010-1.561 1.1 1.1 0 011.548 0 1.112 1.112 0 010 1.561 1.047 1.047 0 01-.768.32z" />
    </svg>
</template>

<script>
export default {
    name: "IconInformation"
}
</script>
