<template>
    <div class="updates-form">
        <div v-if="!showForm" class="updates-form__show-form" @click="showForm = true">
            Click here to post a new update…
        </div>

        <div v-else class="updates-form__form">
            <feed-form
                placeholder="Type your update…"
                :endpoint="endpoint"
                :parent-data="formData"
                @saved="$emit('message-saved', $event)"
                @discard="showForm = false"
            />
        </div>
    </div>
</template>

<script>
import FeedForm from "@c/feed/feed-form";

export default {
    name: "UpdatesForm",
    components: {
        FeedForm
    },
    props: {
        endpoint: {
            type: String,
            default: ""
        },
        formData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            showForm: false
        }
    }
}
</script>

<style lang="scss">

.updates-form {
    .feed-form:not(.form) {
        .quill-editor {
            border: 1px solid #ECECEC;
            border-radius: 6px;
            min-height: 180px;
        }
        .content-error {
            padding-left: 0;
        }
    }

    .feed-toolbar {
        border: none;
    }

    .ql-editor.ql-blank::before {
        color: #9B9B9B;
        letter-spacing: 0.1px;
        font-family: Inter, sans-serif;
    }

    .ql-container {
        font-size: 16px;
        font-family: Inter, sans-serif;
    }
}

</style>

<style lang="scss" scoped>

.updates-form {
    padding: 0 50px;

    &__show-form {
        padding: 20px 0;
        cursor: text;
        letter-spacing: 0px;
        color: #9B9B9B;
        font-size: 14px;
        font-weight: 500;
    }

    &__form {
        padding-top: 30px;
    }
}
</style>
