<template>
    <div class="contact-box">
        <div class="card">
            <div class="card-title">
                <div>
                    <i class="fas fa-building mr-2" />
                    <span> Organization </span>
                </div>
                <div
                    v-if="organization"
                    style="
                        border: solid 1px #a0a0a0;
                        padding: 3px;
                        border-radius: 3px;
                        font-size: 12px;
                        cursor: pointer;
                    "
                    @click="$modal.show('edit-organization-box')"
                >
                    <i class="fa fa-pencil mr-0" />
                    <span> Edit </span>
                </div>
            </div>
            <div class="card-body">
                <div class="contact">
                    <template v-if="organization">
                        <template v-if="!isEditing">
                            <div class="row">
                                <div class="col-4 data-label">
                                    <b>Name</b>
                                </div>
                                <div class="col-8">
                                    <span>{{ organization.name }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 data-label">
                                    <b>Address</b>
                                </div>
                                <div class="col-8">
                                    <span>{{ organization.address }}</span>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <p>No organization is linked to this lead.</p>
                        <a href="#" @click.prevent="openOrganizationModal()">
                            + Link an organization
                        </a>
                    </template>
                </div>
            </div>
        </div>

        <modal
            :adaptive="true"
            :click-to-close="false"
            name="edit-organization-box"
            height="auto"
            width="470px"
        >
            <div class="user-modal">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Edit Organization</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('edit-organization-box')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="row mb-10" style="margin-left: -25px;">
                            <organizations-form
                                :modal="true"
                                :organization="organization"
                                @cancel="$modal.hide('edit-organization-box')"
                                @save="saveOrganization"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import OrganizationsFormMixins from "../organizations/form-mixins";

export default {
    name: "OrganizationBox",
    mixins: [OrganizationsFormMixins],
    props: {
        organizationId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            isEditing: false
        };
    },
    watch: {
        organizationId() {
            this.fetchData();
        }
    },
    async created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.orgId = this.organizationId;

            if (this.orgId > 0) {
                await this.fetchOrganization();
            } else {
                this.organization = null;
            }
        },
        openOrganizationModal() {
            this.$modal.show("organization", {
                organization: this.organization
            });
        },
        saveOrganization(formData) {
            this.isEditing = false;
            this.organization = formData;
        }
    }
};
</script>

<style lang="scss">
.organization-form--footer {
    margin-top: 15px;
}

.contact-box {
    .card {
        padding: 0;

        .card-title {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            border-bottom: 1px solid #e1e8ed;
            margin-bottom: 0;

            i {
                cursor: pointer;
            }
        }

        .card-body {
            .contact {
                padding: 10px;
                border-bottom: 1px dashed #e1e8ed;
                position: relative;

                .row {
                    padding: 7px 0;

                    a {
                        color: var(--secondary-color);
                        text-decoration: none;
                    }

                    div.data-label {
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                .btn {
                    position: absolute;
                    right: 25px;
                    margin-top: 10px;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    .organization-form {
        .row {
            label {
                margin-bottom: 0;
            }

            .form-label {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                padding-right: 0;

                @media (max-width: 1600px) {
                    font-size: 12px;
                }
            }
        }

        &--footer {
            div:first-child {
                display: flex;
                justify-content: flex-end;
            }

            .btn {
                margin-left: 10px;
                position: static !important;
            }
        }
    }
}
</style>
