<!-- eslint-disable linebreak-style -->
<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        style="width: 90px;"
        viewBox="0 0 75.2 37.127"
    >
        <g
            id="Trazado_674"
            transform="translate(-968.924 -220.373)"
            fill="#f7f7f7"
            stroke-miterlimit="10"
        >
            <path
                d="M1042.1,257.5H971.143a2.216,2.216,0,0,1-2.022-3.122l6.508-14.535a2.22,2.22,0,0,0,0-1.811L969.12,223.5a2.217,2.217,0,0,1,2.022-3.123H1042.1a2.216,2.216,0,0,1,2.023,1.311v34.505A2.216,2.216,0,0,1,1042.1,257.5Z"
                stroke="none"
            />
            <path
                d="M 1042.101440429688 256.4997863769531 C 1042.518676757812 256.4997863769531 1042.903076171875 256.2857055664062 1043.124389648438 255.9419097900391 L 1043.124389648438 221.9313812255859 C 1042.902954101562 221.5872650146484 1042.5185546875 221.3729858398438 1042.101440429688 221.3729858398438 L 971.1427612304688 221.3729858398438 C 970.7227172851562 221.3729858398438 970.3507690429688 221.5752716064453 970.1222534179688 221.927978515625 C 969.8936767578125 222.2808074951172 969.8611450195312 222.7031555175781 970.032958984375 223.0868530273438 L 976.5415649414062 237.6228942871094 C 976.9147338867188 238.4578247070312 976.9147338867188 239.4155120849609 976.541259765625 240.2510528564453 L 970.032958984375 254.7865905761719 C 969.8611450195312 255.1700592041016 969.8936767578125 255.5922393798828 970.1221313476562 255.9448699951172 C 970.3505859375 256.2975158691406 970.7225952148438 256.4997863769531 971.1427612304688 256.4997863769531 L 1042.101440429688 256.4997863769531 M 1042.101440429688 257.4997863769531 L 971.1427612304688 257.4997863769531 C 969.5364379882812 257.4997863769531 968.4635620117188 255.8440246582031 969.1203002929688 254.3778076171875 L 975.6286010742188 239.8423919677734 C 975.8861694335938 239.26611328125 975.8861694335938 238.6072235107422 975.6286010742188 238.0309448242188 L 969.1203002929688 223.4955139160156 C 968.4635620117188 222.0292816162109 969.5364379882812 220.3729858398438 971.1427612304688 220.3729858398438 L 1042.101440429688 220.3729858398438 C 1042.975219726562 220.3729858398438 1043.767700195312 220.8867034912109 1044.124389648438 221.68408203125 L 1044.124389648438 256.1892395019531 C 1043.767700195312 256.9866333007812 1042.975219726562 257.4997863769531 1042.101440429688 257.4997863769531 Z"
                stroke="none"
                fill="rgba(201,201,201,0.3)"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        height: {
            type: Number,
            default() {
                return 37.127;
            }
        },
        width: {
            type: Number,
            default() {
                return 75.2;
            }
        }
    }
};
</script>
