<!-- eslint-disable linebreak-style -->
<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        style="width: 90px"
        viewBox="0 0 75.2 37.127"
    >
        <g
            id="Trazado_674"
            transform="translate(-968.924 -220.373)"
            fill="#0088d6"
            stroke-miterlimit="10"
        >
            <path
                d="M 1042.101440429688 256.9997863769531 L 971.1427612304688 256.9997863769531 C 970.5498657226562 256.9997863769531 970.02490234375 256.71435546875 969.7025146484375 256.2167053222656 C 969.380126953125 255.7191009521484 969.334228515625 255.1233367919922 969.5765991210938 254.5821990966797 L 976.0849609375 240.0467224121094 C 976.4003295898438 239.3411560058594 976.4003295898438 238.5321807861328 976.0850830078125 237.8269195556641 L 969.57666015625 223.2911834716797 C 969.3341674804688 222.7498931884766 969.380126953125 222.1539459228516 969.70263671875 221.6561279296875 C 970.0250244140625 221.1584320068359 970.5499267578125 220.8729858398438 971.1427612304688 220.8729858398438 L 1042.101440429688 220.8729858398438 C 1042.74365234375 220.8729858398438 1043.330810546875 221.2329254150391 1043.624389648438 221.7980041503906 L 1043.624389648438 256.0753173828125 C 1043.330932617188 256.6400756835938 1042.743774414062 256.9997863769531 1042.101440429688 256.9997863769531 Z"
                stroke="none"
            />
            <path
                d="M 1042.101440429688 256.4997863769531 C 1042.518676757812 256.4997863769531 1042.903076171875 256.2857055664062 1043.124389648438 255.9419097900391 L 1043.124389648438 221.9313812255859 C 1042.902954101562 221.5872650146484 1042.5185546875 221.3729858398438 1042.101440429688 221.3729858398438 L 971.1427612304688 221.3729858398438 C 970.7227172851562 221.3729858398438 970.3507690429688 221.5752716064453 970.1222534179688 221.927978515625 C 969.8936767578125 222.2808074951172 969.8611450195312 222.7031555175781 970.032958984375 223.0868530273438 L 976.5415649414062 237.6228942871094 C 976.9147338867188 238.4578247070312 976.9147338867188 239.4155120849609 976.541259765625 240.2510528564453 L 970.032958984375 254.7865905761719 C 969.8611450195312 255.1700592041016 969.8936767578125 255.5922393798828 970.1221313476562 255.9448699951172 C 970.3505859375 256.2975158691406 970.7225952148438 256.4997863769531 971.1427612304688 256.4997863769531 L 1042.101440429688 256.4997863769531 M 1042.101440429688 257.4997863769531 L 971.1427612304688 257.4997863769531 C 969.5364379882812 257.4997863769531 968.4635620117188 255.8440246582031 969.1203002929688 254.3778076171875 L 975.6286010742188 239.8423919677734 C 975.8861694335938 239.26611328125 975.8861694335938 238.6072235107422 975.6286010742188 238.0309448242188 L 969.1203002929688 223.4955139160156 C 968.4635620117188 222.0292816162109 969.5364379882812 220.3729858398438 971.1427612304688 220.3729858398438 L 1042.101440429688 220.3729858398438 C 1042.975219726562 220.3729858398438 1043.767700195312 220.8867034912109 1044.124389648438 221.68408203125 L 1044.124389648438 256.1892395019531 C 1043.767700195312 256.9866333007812 1042.975219726562 257.4997863769531 1042.101440429688 257.4997863769531 Z"
                stroke="none"
                fill="#0088d6"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        height: {
            type: Number,
            default() {
                return 37.127;
            },
        },
        width: {
            type: Number,
            default() {
                return 75.2;
            },
        },
    },
};
</script>
