<template>
    <div class="contact-box">
        <div class="card">
            <div class="card-title">
                <div
                    style="
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <i class="fas fa-user mr-2" aria-hidden="true" />
                    <span> Person </span>
                </div>
                <div
                    v-if="person"
                    style="
                        border: solid 1px #a0a0a0;
                        padding: 3px;
                        border-radius: 3px;
                        font-size: 12px;
                        cursor: pointer;
                    "
                    @click="open"
                >
                    <i class="fa fa-pencil mr-0" />
                    <span> Edit </span>
                </div>
            </div>
            <div class="card-body">
                <div class="contact">
                    <template v-if="person">
                        <peoples-form
                            v-if="isEditing"
                            :contacts="contacts"
                            :contacts-types="contactsTypes"
                            :person="person"
                            :modal="true"
                            @cancel="isEditing = false"
                            @save="savePerson"
                        />
                        <template v-if="!isEditing">
                            <div class="row">
                                <div class="col-4 data-label">
                                    <span>
                                        <b> Full name </b>
                                    </span>
                                </div>
                                <div class="col-8">
                                    <span>{{ person.name }}</span>
                                </div>
                            </div>
                            <template v-for="_type in contactsTypes">
                                <div
                                    v-for="contact in contacts[_type.id]"
                                    :key="`type-${_type.id}-${contact.id}`"
                                    class="row"
                                >
                                    <div class="col-4 data-label">
                                        <b>{{ _type.name }}</b>
                                    </div>
                                    <div class="col-8">
                                        <span>{{ contact.value }}</span>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <p>No person is linked to this lead.</p>
                        <a href="#" @click.prevent="openPersonModal()">
                            + Link a person
                        </a>
                    </template>
                </div>
            </div>
        </div>
        <modal
            :adaptive="true"
            :click-to-close="false"
            name="edit-contact-box"
            height="auto"
            width="490px"
        >
            <div class="user-modal">
                <div class="row modal-header">
                    <div
                        class="col d-flex align-items-center"
                        style="color: var(--base-color)"
                    >
                        <h5>Edit Person</h5>
                        <span
                            class="ml-auto"
                            style="cursor: pointer"
                            @click="$modal.hide('edit-contact-box')"
                        >
                            x
                        </span>
                    </div>
                </div>
                <div class="row modal-body">
                    <div class="col">
                        <div slot="container" class="">
                            <peoples-form
                                :contacts="contacts"
                                :contacts-types="contactsTypes"
                                :person="person"
                                :modal="true"
                                @cancel="$modal.hide('edit-contact-box')"
                                @save="savePerson"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import _groupBy from "lodash/groupBy";
import PeoplesFormMixins from "../peoples/form-mixins";

export default {
    name: "ContactBox",
    mixins: [PeoplesFormMixins],
    props: {
        peoplesId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            isEditing: false
        };
    },
    watch: {
        peoplesId() {
            this.fetchData();
        }
    },
    async created() {
        await this.fetchContactsTypes();
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.personId = this.peoplesId;

            if (this.personId > 0) {
                await this.fetchPerson();
                await this.fetchContacts();
            } else {
                this.person = null;
            }
        },
        openPersonModal() {
            this.$modal.show("person", {
                person: this.person
            });
        },
        savePerson(formData) {
            this.$modal.hide("edit-contact-box");
            this.person = formData;
            this.contacts = _groupBy(formData.contacts, "contacts_types_id");

            setTimeout(() => {
                this.$emit("updated", true);
            }, 1500);
        },
        open() {
            this.$modal.show("edit-contact-box");
        }
    }
};
</script>

<style lang="scss">
.contact-box {
    .card {
        padding: 0;

        .card-title {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            border-bottom: 1px solid #e1e8ed;
            margin-bottom: 0;

            i {
                cursor: pointer;
            }
        }

        .card-body {
            .contact {
                padding: 10px;
                border-bottom: 1px dashed #e1e8ed;
                position: relative;

                .row {
                    padding: 7px 0;

                    a {
                        color: var(--secondary-color);
                        text-decoration: none;
                    }

                    div.data-label {
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;

                        @media (max-width: 1480px) {
                            padding-right: 0;
                            font-size: 12px;
                        }
                    }
                }

                .btn {
                    position: absolute;
                    right: 25px;
                    margin-top: 10px;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    .people-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        justify-items: flex-start;
        padding: 10px;
        gap: 5px;
        width: 100%;

        .row {
            label {
                margin-bottom: 0;
            }

            .form-label {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                padding-right: 0;

                @media (max-width: 1600px) {
                    font-size: 12px;
                }
            }
        }

        .contact-delete {
            align-items: center;
            display: flex;
            padding: 0;

            i {
                cursor: pointer;
            }
        }

        &--footer {
            div:first-child {
                display: flex;
                justify-content: flex-end;
            }

            .btn {
                margin-left: 10px;
                position: static !important;
            }
        }
    }
}
</style>
