<template>
    <div class="form feed-form">
        <div v-if="label" class="px-3 pt-3">
            <h6>{{ label }}</h6>
        </div>
        <feed-base-form
            ref="FeedForm"
            v-model="formData"
            :focus-on-mounted="isMessage"
            :show-gallery="true"
            :placeholder="placeholder"
            @validated="submit"
        />

        <div class="feed-toolbar">
            <small
                v-if="showAttachFile"
                class="toolbar-action"
                @click="openUploader()"
            >
                <i class="fas fa-paperclip mr-2" /> Attach Files
            </small>

            <button
                class="btn btn-discard"
                :disabled="isLoading"
                @click.prevent="cancel()"
            >
                Discard
            </button>

            <button
                class="btn btn-post ml-2"
                :disabled="isLoading"
                @click.prevent="validateForm()"
            >
                Post
            </button>
        </div>
    </div>
</template>

<script>
import FeedBaseForm from "./feed-base-form.vue";

export default {
    components: {
        FeedBaseForm
    },
    props: {
        endpoint: {
            type: String,
            default: "messages"
        },
        parentData: {
            type: Object,
            default() {
                return {}
            }
        },
        label:{
            type: String,
            default: ""
        },
        mode:{
            type: String,
            default: "message"
        },
        placeholder:{
            type: String,
            default: ""
        },
        feedData: {
            type: Object,
            default() {
                return {}
            }
        },
        showAttachFile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formData: {
                leads_uuid: "",
                files: [],
                verb: "lead",
                message: {
                    text: ""
                }
            },
            hasError: false,
            isLoading: false
        }
    },
    computed: {
        isMessage() {
            return this.mode == "message";
        }
    },
    methods: {
        async submit(formData) {

            formData.leads_uuid = this.parentData.uuid;

            let method = "POST";
            let url = this.endpoint;

            if (formData.id) {
                method = "PUT"
                url += `/${formData.id}`
            }

            if (!this.isLoading) {
                this.isLoading = true;
                axios({
                    url: url,
                    data: formData,
                    method: method
                })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.$notify({
                            title: "Success",
                            text: "New message added",
                            type: "success"
                        });

                        this.$emit("saved", data)
                        this.$refs.FeedForm.clearForm();
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response ? error.response.data.errors.message : "Error",
                            type: "error"
                        });
                        return false;
                    })
                    .finally((result) => {
                        this.isLoading = false;
                        return result;
                    })
            }
        },
        validateForm() {
            this.$refs.FeedForm.validateForm()
        },
        cancel() {
            this.$refs.FeedForm.cancel()
            this.$emit("discard")
        },
        openUploader() {
            this.$refs.FeedForm.openUploader()
        }
    }
}
</script>

<style lang="scss" scoped>

.feed-form {
    .feed-toolbar {
        display: flex;
        padding: 10px 0 30px;
        justify-content: flex-end;
        position: relative;

        .btn {
            width: 85px;
            font-size: 16px;
            letter-spacing: 0.07px;
            padding: 6px 0;
            border-radius: 2px;
        }

        .btn-discard {
            background: rgba(187, 187, 187, 0.15);
            color: #9B9B9B;
        }

        .btn-post {
            background: #35D8F2;
            color: #FFF;
        }
    }

    .toolbar-action {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
