<template>
    <skeleton v-if="isSkeleton" />
    <div v-else class="contact">
        <div class="follower-item d-flex pl-2">
            <div class="data-label text-right mr-2">
                <i class="fas fa-user mr-0" />
            </div>
            <div class="d-flex justify-between w-100">
                <div class="mr-2">
                    {{ username }}
                    <span v-if="isAdmin" class="follower-item__role">Manager</span>
                </div>
                <div>
                    <i class="fa fa-trash delete-btn" @click="$emit('deleted')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MembersBoxItemSkeleton from "./members-box-item-skeleton.vue"

export default {
    name: "MemberBoxItem",
    components: {
        Skeleton: MembersBoxItemSkeleton
    },
    props: {
        member: {
            type: Object,
            default() {
                return {}
            }
        },
        isSkeleton: {
            type: Boolean
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        username() {
            const { users: user = {} } = this.member;
            return `${user.firstname || ""} ${user.lastname || ""}`;
        }
    }
}
</script>

<style lang="scss">

.follower-item:hover .delete-btn {
    display: inline-block;
}

.delete-btn {
    cursor: pointer;
    transition: all ease .3s;
    display: none;

    &:hover {
        color: rgba(247, 62, 62, 0.609);
    }
}

.follower-item__role {
    color: #8B8B8B;
    font-size: 12px;
    margin-left: 12px;
}
</style>
