<template>
    <pipeline-middle-active v-if="active" :height="height" :width="width" />
    <pipeline-middle-default v-else-if="pass" :height="height" :width="width" />
    <pipeline-middle-inactive v-else :height="height" :width="width" />
</template>

<script>
import PipelineMiddleActive from "./pipeline-middle-active.vue";
import PipelineMiddleInactive from "./pipeline-middle-inactive.vue";
import PipelineMiddleDefault from "./pipeline-middle-default.vue";

export default {
    name: "PipelineMiddle",
    components: {
        PipelineMiddleActive,
        PipelineMiddleInactive,
        PipelineMiddleDefault
    },
    props: {
        active: {
            type: Boolean,
            default: false
        },
        pass: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default() {
                return 37.127;
            }
        },
        width: {
            type: Number,
            default() {
                return 83.916;
            }
        }
    }
};
</script>
