<template>
    <modal
        :adaptive="true"
        :click-to-close="false"
        name="user"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose()"
    >
        <div class="user-modal">
            <div class="row modal-header">
                <div class="col d-flex align-items-center">
                    <h5>Add a follower</h5>
                    <a class="ml-auto" href="#" @click="$modal.hide('user')">x</a>
                </div>
            </div>
            <div class="row modal-body">
                <div class="col">
                    <div slot="container" class="row m-b-20">
                        <div class="col-12">
                            <resource-selector
                                v-model="user"
                                :resource="user"
                                :searchable-by="['firstname', 'lastname']"
                                :process-response="processResponse"
                                :custom-label="userLabel"
                                label="firstname"
                                name="user"
                                placeholder="Select an user"
                                resource-id="id"
                                resource-url="/users?relationships=roles"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="display: flex;">
                <div>
                    <button class="btn btn-danger mr-2 btn-normal" @click="$modal.hide('user')">
                        Cancel
                    </button>
                </div>
                <div>
                    <button :disabled="!user" class="btn btn-primary btn-normal" @click="$emit('select', user)">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { differenceBy as _differenceBy } from "lodash";
import ResourceSelector from "@c/resource-selector";
import { ROLES } from "@config/constants";

export default {
    name: "UserModal",
    components: {
        ResourceSelector
    },
    props: {
        disabledUsers: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            user: null
        }
    },
    methods: {
        beforeOpen(event) {
            this.user = event.params?.user || null;
        },
        beforeClose() {
            this.user = null;
        },
        processResponse(response) {
            const responsed = _differenceBy(response, this.disabledUsers.map(user => {
                user.id = Number(user.id);
                return user;
            }), "id")
            return responsed;
        },
        userLabel(user) {
            const { roles = [] } = user;
            const isAdmin = Array.isArray(roles) ? Boolean(roles.find(role => role.name.toLowerCase() == ROLES.ADMINS)) : false;
            const roleName = isAdmin ? " (Managers)" : "";
            return `${user.firstname} ${user.lastname}${roleName}`;
        }
    }
}
</script>

<style lang="scss" scoped>
.user-modal {
    .modal-header {
        h5 {
            color: var(--base-color);
            margin: 0;
        }
    }

    .section-title {
        font-weight: 700;
    }

    .btn-success:disabled {
        cursor: not-allowed;
    }
}
</style>
