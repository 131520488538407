<template>
    <div class="container-fluid container-fixed-lg">
        <h1 v-if="!modal" class="section-title p-l-10">
            PERSON
        </h1>
        <div class="row">
            <div class="col-md-12">
                <peoples-form
                    v-if="isReady"
                    :contacts="contacts"
                    :contacts-types="contactsTypes"
                    :person="person"
                    :modal="modal"
                    mode="standalone"
                    @cancel="cancel()"
                    @save="cancel()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PeoplesFormMixins from "./form-mixins";

export default {
    name: "PeoplesResource",
    mixins: [PeoplesFormMixins],
    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isReady: false
        };
    },
    async created() {
        this.personId = this.$route.params.id;
        await this.fetchContactsTypes();

        if (this.personId) {
            await this.fetchPerson();
            await this.fetchContacts();
        } else {
            this.person = {
                contacts: []
            };
            await this.setContacts();
        }

        this.isReady = true;
    },
    methods: {
        cancel() {
            if (!this.modal) {
                this.$router.push({
                    name: "browse",
                    params: {
                        resource: "peoples"
                    }
                });
            } else {
                this.$modal.hide("Create-People");
            }
        }
    }
};
</script>

<style lang="scss">
.people-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    > div {
        flex: 1 50%;
        max-width: 49%;
    }

    > div:last-child {
        max-width: 100%;
    }

    .form-group {
        .form-label,
        > .col-9 {
            flex-basis: 100%;
            max-width: 100%;
        }

        .contact-delete {
            display: flex;
            align-items: center;
        }
    }

    .add-more {
        font-size: 14px;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    &--footer {
        .btn {
            border-radius: 5px;
            font-size: 14px;
            margin-top: 0;
            padding: 5px 20px;
        }
    }
}
</style>
