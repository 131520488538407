<template>
    <nav class="activities-tabs">
        <ul class="activities-tabs__list">
            <li
                v-for="tab in tabs"
                :key="tab.name"
                class="activities-tabs__item"
                :class="{ 'activities-tabs__item--active': value == tab.name }"
                @click="$emit('input', tab.name)"
            >
                <span
                    v-if="tab.svg"
                    class="activities-tabs__icon"
                    v-html="tab.icon"
                />

                <i v-else :class="`fa-solid ${tab.icon} mr-2`" />
                {{ tab.label || tab.name }}
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "ActivityTabs",
    props: {
        tabs: {
            type: Array,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss">
.activities-tabs__item--active {
    .activities-tabs__icon {
        svg path,
        g {
            // fill: #22cae5;
            fill: var(--base-color);
        }
    }
}
</style>

<style lang="scss" scoped>
.activities-tabs {
    padding: 0 35px;
    border-bottom: 1px solid #ececec;

    &__list {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
    }

    &__item {
        padding: 15px;
        font-size: 14px;
        letter-spacing: 0px;
        color: #707070;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    &__item--active {
        // background: rgba(34, 202, 229, 0.05);
        background-color: #edf5fc;
        // color: #22cae5;
        color: var(--base-color);
        // border-bottom: 2px solid #1fcae5;
        border-bottom: 2px solid var(--base-color);
        cursor: default;

        .activities-tabs__icon {
            svg {
                // fill: #1975BC;
                fill: var(--base-color);
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
}
</style>
