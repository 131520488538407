<template>
    <div class="contact-box">
        <div class="card">
            <div class="card-title">
                <span class="d-flex align-items-center">
                    <div>
                        <i class="fas fa-building mr-2" aria-hidden="true" />
                        <span> Followers </span>
                    </div>
                    <tooltip
                        popper-class="card-note"
                        effect="dark"
                        content="A lead must have at least one (1) manager as a follower"
                        placement="right"
                        :visible-arrow="false"
                    >
                        <icon-information class="card-note-icon" />
                    </tooltip>
                </span>
            </div>
            <div class="card-body">
                <template v-if="isLoading">
                    <members-box-item is-skeleton />
                    <members-box-item is-skeleton />
                    <members-box-item is-skeleton />
                </template>

                <template v-else-if="value.length">
                    <members-box-item
                        v-for="member in sortedMembersByRole"
                        :key="member.id"
                        :member="member"
                        :is-admin="member.isAdmin"
                        @deleted="deleteAccess(member)"
                    />
                </template>
                <div v-else class="contact">
                    There are no followers.
                </div>

                <div class="contact">
                    <a
                        href="#"
                        @click.prevent="openMemberModal()"
                    >+ Add followers to this lead</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _orderBy from "lodash/orderBy";
import { MessageBox, Tooltip } from "element-ui";
import PeoplesFormMixins from "../peoples/form-mixins";
import MembersBoxItem from "./members-box-item";
import IconInformation from "@c/icons/icon-information.vue";
import { ROLES } from "@config/constants";

const MIN_MEMBERS_ADMIN_NEEDED = 1;

export default {
    name: "MemberBox",
    components: {
        MembersBoxItem,
        Tooltip,
        IconInformation
    },
    mixins: [PeoplesFormMixins],
    props: {
        leadId: {
            type: [String, Number],
            default: ""
        },
        value: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            selectedUser: {},
            isEditing: false,
            isLoading: true
        };
    },
    computed: {
        sortedMembersByRole() {
            return _orderBy(
                this.membersWithRole,
                ["isAdmin", "users.firstname"],
                ["desc", "asc"]
            );
        },
        membersWithRole() {
            const members = this.value.map((member) => {
                const { users: { roles = [] } = {} } = member;
                // Add the Array.isArray condition until backend returns a good map of user roles
                const isAdmin = Array.isArray(roles)
                    ? Boolean(
                        roles.find(
                            (role) => role.name.toLowerCase() == ROLES.ADMINS
                        )
                    )
                    : false;
                return {
                    ...member,
                    isAdmin
                };
            });
            return members;
        }
    },
    watch: {
        leadId: {
            handler() {
                this.leadId && this.getMembers();
            },
            immediate: true
        }
    },
    methods: {
        openMemberModal() {
            this.$modal.show("user");
        },

        getMembers() {
            return axios({
                url: `/leads/${this.leadId}/access`
            }).then(({ data }) => {
                this.isLoading = false;
                this.$emit("input", data);
            });
        },

        async deleteAccess(member) {
            if (!(await this.canDeleteMember(member))) {
                return;
            }

            const memberId = member.users_id || member.id;
            const idField = member.users_id ? "users_id" : "id";
            const fullname = `${member.users.firstname} ${member.users.lastname}`;
            const message = `Are you sure you want to remove "${fullname}" as a follower?`;
            const isConfirmed = await MessageBox.confirm(message, {
                type: "warning",
                customClass: "followers-remove",
                iconClass: "fas fa-exclamation-triangle",
                confirmButtonText: "Remove",
                showClose: false
            }).catch(() => false);

            if (isConfirmed) {
                return axios({
                    url: `/leads/${this.leadId}/users/${memberId}/access`,
                    method: "DELETE"
                }).then(() => {
                    this.$emit(
                        "input",
                        this.value.filter(
                            (member) => member[idField] != memberId
                        )
                    );
                });
            }
        },

        async canDeleteMember(member) {
            if (!member.isAdmin) {
                return true;
            }

            const adminMembersQty = this.membersWithRole.reduce(
                (qty, member) => {
                    return member.isAdmin ? qty + 1 : qty;
                },
                0
            );
            const canDeleteAdmin = adminMembersQty > MIN_MEMBERS_ADMIN_NEEDED;
            if (!canDeleteAdmin) {
                const alertMsg = `
                    <b>Can’t delete this follower</b>
                    <div>A Lead must have at least one (1) manager as a follower.</div>
                `;
                await MessageBox.alert(alertMsg, {
                    type: "warning",
                    customClass: "followers-remove",
                    iconClass: "fas fa-exclamation-triangle",
                    confirmButtonText: "Go back",
                    showClose: false,
                    dangerouslyUseHTMLString: true
                });
            }
            return canDeleteAdmin;
        }
    }
};
</script>

<style lang="scss">
.card-note.el-tooltip__popper {
    background-color: #707070;
    color: white;
    font-size: 14px;
    padding: 6px;
    margin-left: 5px;
}

.organization-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: flex-start;
    padding: 10px;
    gap: 5px;
}

.contact-box {
    .card {
        padding: 0;

        .card-title {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            border-bottom: 1px solid #e1e8ed;
            margin-bottom: 0;

            i {
                cursor: pointer;
            }
        }

        .card-body {
            .contact {
                padding: 10px;
                border-bottom: 1px dashed #e1e8ed;
                position: relative;

                .row {
                    padding: 7px 0;

                    a {
                        color: var(--secondary-color);
                        text-decoration: none;
                    }

                    div.data-label {
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                .btn {
                    position: absolute;
                    right: 25px;
                    margin-top: 10px;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .card-note-icon {
            color: #9b9b9b;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .people-form {
        .row {
            label {
                margin-bottom: 0;
            }

            .form-label {
                align-items: center;
                display: flex;
                justify-content: flex-end;
            }
        }

        .contact-delete {
            align-items: center;
            display: flex;
            padding: 0;

            i {
                cursor: pointer;
            }
        }

        &--footer {
            div:first-child {
                display: flex;
                justify-content: flex-end;
            }

            .btn {
                margin-left: 10px;
                position: static !important;
            }
        }
    }
}
</style>

<style lang="scss">
.el-message-box.followers-remove {
    .fa-exclamation-triangle {
        color: #ff8484;
    }

    .el-message-box {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__status {
            position: initial;
            transform: initial;
            padding-bottom: 10px;

            &::before {
                font-size: 32px;
            }
        }

        &__header {
            padding-top: 0;
        }

        &__message {
            padding: 0;
            text-align: center;
        }

        &__btns {
            text-align: center;
        }
    }
}
</style>
