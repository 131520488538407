<template>
    <modal
        :adaptive="true"
        :click-to-close="false"
        name="organization"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose()"
    >
        <div class="organization-modal">
            <div class="row modal-header">
                <div class="col d-flex align-items-center">
                    <h5>Link an organization</h5>
                    <a class="ml-auto" href="#" @click="$modal.hide('organization')">x</a>
                </div>
            </div>
            <div class="row modal-body">
                <div class="col">
                    <div slot="container" class="row m-b-20">
                        <div class="col-12">
                            <resource-selector
                                v-model="organization"
                                :resource="organization"
                                :searchable-by="['name' , 'id']"
                                :show-add-resource="true"
                                :show-no-options="false"
                                :show-no-results="false"
                                name="organization"
                                placeholder="Search for an organization"
                                resource-id="id"
                                resource-label="organization"
                                resource-url="/organizations"
                                @add-resource="addOrganization"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row modal-footer">
                <div class="col">
                    <button class="btn btn-danger mr-2 btn-normal" @click="$modal.hide('organization')">
                        Cancel
                    </button>
                </div>
                <div class="col">
                    <button :disabled="disableLinkButton" class="btn btn-primary btn-normal" @click="linkOrganization()">
                        Link an organization
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import ResourceSelector from "@c/resource-selector";

export default {
    name: "OrganizationModal",
    components: {
        ResourceSelector
    },
    data() {
        return {
            newOrganization: "",
            organization: null
        }
    },
    computed: {
        disableLinkButton() {
            return !this.organization && this.newOrganization === "";
        }
    },
    methods: {
        addOrganization(name) {
            this.newOrganization = name;
        },
        beforeOpen(event) {
            this.organization = event.params.organization;
        },
        beforeClose() {
            this.organization = null;
        },
        createOrganization() {
            if (this.newOrganization === "") {
                return Promise.resolve();
            }

            return axios({
                url: "/organizations",
                method: "POST",
                data: {
                    name: this.newOrganization
                }
            }).then(({ data }) => {
                this.organization = data;
                this.newOrganization = "";
            });
        },
        linkOrganization() {
            this.createOrganization().then(() => {
                this.$emit("select", this.organization);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.organization-modal {
    .modal-header {
        h5 {
            color: var(--base-color);
            margin: 0;
        }
    }

    .section-title {
        font-weight: 700;
    }

    .btn-success:disabled {
        cursor: not-allowed;
    }
}
</style>
