<template>
    <div class="controls-body">
        <!-- <div style="width: 20%; display: flex; margin-left: 10px;" class="mr-8 ml-8">
            <div class="mr-3 pointer">
                <i class="fa-solid fa-circle-plus" />
            </div>

            <el-tooltip
                effect="dark"
                content="Needs analysis"
                placement="top"
            >
                <div class="mr-3 pointer">
                    <i class="fa-solid fa-magnifying-glass" />
                </div>
            </el-tooltip>

            <div class="mr-3 pointer">
                <i class="fa-regular fa-id-card" />
            </div>

            <el-tooltip
                effect="dark"
                content="Get docs"
                placement="top"
            >
                <div class="mr-3 pointer">
                    <i class="fa-solid fa-file" />
                </div>
            </el-tooltip>

            <div class="mr-3 pointer">
                <i class="fa-solid fa-dollar-sign" />
            </div>
        </div> -->

        <div
            style="
                width: 100%;
                border: 1px solid #ececec;
                display: flex;
                justify-content: space-between;
                border-radius: 5px;
            "
        >
            <input
                v-model="message"
                type="text"
                style="margin-right: 10px;"
                class="mr-3 input-control-body"
                placeholder="Type your message..."
                @keyup.enter="sendMesssage"
            >

            <button
                v-show="message.length"
                class="send"
                style="border-radius: 0%;"
                @click="sendMesssage"
            >
                send
            </button>

            <!-- <select v-show="!message.length" class="select-control-body">
                <option value="sms" selected>
                    Text message
                </option>
                <option value="ws">
                    Whatssap
                </option>
                <option value="ms">
                    Messenger
                </option>
                <option value="nt">
                    Note
                </option>
            </select> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: ""
        };
    },
    methods: {
        sendMesssage() {
            if (this.message) {
                this.$emit("send", this.message);
                this.message = "";
            }
        }
    }
};
</script>

<style>
.controls-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    color: #30d0ea;
    height: 90px;
}

.input-control-body {
    width: 80%;
    border: none;
    padding: 5px;
    outline: none;
    border-radius: 5px;
}

.select-control-body {
    border: none;
    border-radius: 5px;
    outline: none;
}

.send {
    outline: none;
    border: none;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}
</style>
