<template>
    <div>
        <gw-browse
            ref="gwBrowse"
            :resource="resource"
            :http-options="{ baseURL, headers: { Authorization: token } }"
            :query-params="queryParams"
            :append-params="appendParams"
            :show-resource-actions="false"
            :show-bulk-actions="false"
            :show-actions-delete="false"
            :show-actions-edit="false"
            :results-per-page-options="[10]"
            :show-results-per-page="false"
            :show-pagination-bottom="false"
            :pagination-data="paginationData"
            pagination-path=""
        />
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-danger mr-3 btn-fixed-height" type="button" @click="cancel()">
                    Cancel
                </button>
                <button class="btn btn-primary btn-fixed-height" type="button" @click="proceed()">
                    Proceed
                </button>
            </div>
        </div>
    </div>
</template>

<script>
const { GwBrowse } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);

export default {
    name: "PeopleSearchResults",
    components: {
        GwBrowse
    },
    props: {
        appendParams: {
            type: Object,
            required: true
        },
        cancel: {
            type: Function,
            required: true
        },
        proceed: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            baseURL: process.env.VUE_APP_BASE_API_URL,
            token: this.$store.state.User.token || Cookies.get("token"),
            resource: {
                name: "Possible Matches",
                slug: "peoples"
            },
            queryParams: {
                sort: "sort",
                page: "page",
                perPage: "limit"
            }
        };
    },
    methods: {
        paginationData(data) {
            const paginationData = {
                total: parseInt(data.total_rows),
                per_page: parseInt(data.limit),
                current_page: parseInt(data.page),
                last_page: parseInt(data.total_pages)
            }

            const nextParams = this.$refs.gwBrowse.getAllQueryParams();
            nextParams.page = nextParams.page == paginationData.last_page ? null : nextParams.page + 1;
            const prevParams = this.$refs.gwBrowse.getAllQueryParams();
            prevParams.page = prevParams.page == 1 ? null : prevParams.page - 1;

            const nextQuery = Object.keys(nextParams).map(key => `${key}=${nextParams[key]}`);
            const prevQuery = Object.keys(prevParams).map(key => `${key}=${prevParams[key]}`);

            paginationData.next_page_url = nextParams.page === null ? null : `${this.baseURL}?${nextQuery.join("&")}&format=true`;
            paginationData.prev_page_url = prevParams.page === null ? null : `${this.baseURL}?${prevQuery.join("&")}&format=true`;
            paginationData.from = (paginationData.current_page - 1) * paginationData.per_page + 1;
            paginationData.to = paginationData.from + paginationData.per_page - 1;

            return paginationData;
        }
    }
}
</script>
