<template>
    <div class="dealer-list dealer-table">
        <div class="d-flex mb-4 justify-content-between browse-actions custom-browse-actions">
            <div class="left-controls d-flex ml-1">
                <div class="input-group search-bar mr-4">
                    <input
                        v-model="searchOptions.text"
                        type="text"
                        class="form-control"
                        :placeholder="`Search vehicle`"
                        @keydown.enter="getData()"
                    >
                    <div v-if="searchOptions.text" class="input-group-append">
                        <button class="btn btn-danger btn-sm" @click="clearSearch()">
                            <i class="fa fa-times" />
                        </button>
                    </div>
                    <div class="input-group-append ml-2">
                        <button class="btn btn-primary dealer-search__button" @click="getData()">
                            <i class="fa fa-search" />
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <el-table
            ref="ElTable"
            v-loading="isLoading"
            :data="list"
            stripe
            :height="'500px'"
            style="width: 100%"
            @selection-change="setSelectedItems"
        >
            <el-table-column
                type="selection"
                width="55"
            />
            <el-table-column
                v-for="field in fields"
                :key="field.name"
                :prop="field.name"
                :label="field.title"
                :width="field.width"
            >
                <template slot-scope="scope">
                    <div v-if="field.render" v-html="field.render(scope.row, field)" />
                    <div v-else>
                        {{ scope.row[field.name] }}
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="d-flex justify-content-end py-2">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="paginationData.limit"
                :total="paginationData.total"
                :current-page.sync="paginationData.currentPage"
            />
        </div>
        <div class="d-flex justify-content-end align-items-center my-2">
            <button class="btn btn-danger btn-normal" @click="$emit('cancel')">
                Cancel
            </button>
            <button class="btn btn-primary btn-normal ml-2" :disabled="!selectedItems.length" @click="selectItem">
                Send Selected ({{ selectedItems.length }})
            </button>
        </div>
    </div>
</template>

<script>
import extAxios from "axios";

export default {
    props: {
        companiesId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_PUBLISHER_API,
            endpoint: "/search/vehicles",
            linkEndpoint: `https://${process.env.VUE_APP_DM_SUBDOMAIN}.dealerappcenter.com/vehicle/{id}/general`,
            selectedItems: [],
            isLoading: false,
            searchOptions: {
                text: "",
                filters: ["make", "model", "trim"]
            },
            list: [],
            paginationData: {
                currentPage: 1,
                total: 0,
                limit: 25,
                totalPages: 1,
                pageSizes: [3, 5, 10, 20, 50, 100]
            },
            fields: [
                {
                    name: "photos",
                    title: "Photos",
                    width: "150",
                    render(row) {
                        return `
                        <div class="empty-image">
                            <img src="${row.photos.length ? row.photos[0].url : ""}" />
                            <div class="image-count"> ${row.photos.length} </div>
                        </div>`;
                    }
                },
                {
                    name: "make",
                    title: "Make|Model|trim",
                    width: 180,
                    render(row) {
                        return `<div>
                            <h6 class="dealer-blue bolder">${row.make}</h6>
                            <div>${row.model}</div>
                            <div>${row.trim}</div>
                        </div>`;
                    }
                },
                {
                    name: "year",
                    title: "Year"
                },
                {
                    name: "exterior_color",
                    title: "Ext. Color"
                },
                {
                    name: "vin",
                    title: "Vin"
                },
                {
                    name: "stock_number",
                    title: "Stock"
                },
                {
                    name: "rooftop",
                    title: "Rooftop",
                    render(row) {
                        return row.rooftop.city
                    }
                },
                {
                    name: "selling_price",
                    title: "Price",
                    render(row) {
                        return row.selling_price.price.value
                    }
                }
            ]
        }
    },
    computed: {
        searchParams() {
            let params = this.searchOptions.text && this.searchOptions.filters && this.searchOptions.filters.map(filter => {
                return `${filter}:%${this.searchOptions.text}%`
            }).join(";");

            const rooftopId = `rooftop.id:${this.companiesId}`;

            if (params) {
                params += ",";
            }

            params += rooftopId;

            return params;
        }
    },
    watch: {
        "paginationData.currentPage": {
            handler(currentPage) {
                this.getData(currentPage);
            },
            immediate: true
        },
        "paginationData.limit"() {
            this.getData();
        }
    },
    methods: {
        setSelectedItems(value) {
            this.selectedItems = value;
        },
        selectItem() {
            const items = this.selectedItems.map((item) => {
                return {
                    message: this.linkEndpoint.replace("{id}", item.id),
                    attributes: {
                        vehicleId: item.id,
                        type: "dealer-vehicle",
                        url: this.linkEndpoint.replace("{id}", item.id),
                        photos: item.photos.length ? [item.photos[0]] : [],
                        make: item.make,
                        model: item.model,
                        model_number: item.model_number,
                        mileage: item.mileage,
                        trim: item.trim,
                        stock_number: item.stock_number,
                        year: item.year,
                        description: item.description,
                        rooftop: item.rooftop.city,
                        price: item.selling_price.price.value,
                        price_range: item.selling_price.range.value,
                        price_payment: item.selling_price.payment.value
                    }
                };
            });
            this.$emit("selected", items)
        },
        getData(page = 1) {
            const client = extAxios.create();
            this.isLoading = true
            client({
                url:this.baseUrl + this.endpoint,
                params: {
                    limit: this.paginationData.limit,
                    token: "1e92078e-ffd6-4b85-955a-8b8ad8c61e53",
                    sort: "year|desc,photos_count|desc",
                    list: "make,model,body_style,year",
                    page,
                    q: this.searchParams ? `(${this.searchParams})` : null
                }
            }).then(({ data }) => {
                this.$refs.ElTable.$el.querySelector(".el-table__body-wrapper").scrollTop = 0;
                this.list = data.data;
                this.paginationData.total = data.total
                this.paginationData.totalPages = data.total_pages
            }).finally(() => {

                this.isLoading = false
            })
        },

        clearSearch() {
            this.searchOptions.text = "";
            this.getData();
        }
    }
}
</script>

<style lang="scss">
    .dealer-list {
        padding: 20px;
        width: 100%;
        height: 95%;
    }

    .empty-image {
        width: 120px;
        background: #ccc;
        height: 90px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .image-count {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: calc(50% - 15px);
            border: 1px solid white;
            right: -15px;
            width: 30px;
            height: 30px;
            background: #555;
            color: white;
            border-radius: 50%;
        }
    }

    .bolder {
        font-weight: bolder;
    }
    .dealer-blue {
        color: dodgerblue;
    }
</style>
