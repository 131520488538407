<template>
    <div class="message-body">
        <!-- <Time /> -->

        <Message
            v-for="(message, index) in messages"
            :key="index"
            :is-my="message.autor === identity"
            :message="message"
        />
    </div>
</template>

<script>
import Message from "./Message.vue";
// import Time from "./Time.vue";

export default {
    name: "Body",
    components: {
        Message
        // Time
    },
    props: {
        messages: {
            type: Array,
            // required: true,
            default: () => []
        },
        connection: {
            type: String,
            default: () => ""
        },
        identity: {
            type: String,
            default: () => ""
        }
    }
};
</script>

<style scoped>
.message-body {
    height: 580px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-items: flex-end;

    flex-shrink: 2;
    overflow-y: auto;
}
</style>
