<template>
    <div id="app" :class="{ 'full-height' : !($route.meta && $route.meta.requiresAuth == undefined) }" class="app">
        <fullscreen-loader />
        <notifications />
        <basic-modal />
        <app-sidebar
            v-if="$route.meta && $route.meta.requiresAuth == undefined"
            :resources="resources"
            :show-sidebar="showSidebar"
            :sidebar-state="sidebarState"
            @handle-sidebar="handleSidebar"
        >
            <img slot="app-logo" :src="appLogo">
        </app-sidebar>
        <subscription-bar v-if="showSubscriptionBar" />
        <div
            :class="{ 'menu-pinned': sidebarState == 'opened', 'subscription-bar': showSubscriptionBar }"
            class="page-container"
        >
            <app-header
                v-if="$route.meta && $route.meta.requiresAuth == undefined && companyData"
                :apps-list="appsList"
                :hide-companies-switcher="hideCompanySelector"
                :companies-list="companiesList"
                :company-branch-data="companyBranchData"
                :company-data="companyData"
                :domain="domain"
                :notifications-count="notificationsCount"
                :show-notifications="showNotifications"
                :show-sidebar="showSidebar"
                :sidebar-state="sidebarState"
                :user-data="userData"
                @handle-sidebar="handleSidebar(!showSidebar)"
                @logout="logout()"
                @selected-company="switchCompanyGuard"
                @toggle-notifications="toggleNotifications"
                @rooftop="all"
            />
            <div class="page-content-wrapper animated">
                <div class="content sm-gutter">
                    <router-view
                        :app-settings="appSettings"
                        class="container-fluid container-fixed-lg"
                        transition="fade"
                        transition-mode="out-in"
                    />

                    <div
                        v-if="featureFlags.leads_chat"
                        class="floating-chat"
                        :class="{expanded: isChatExpanded}"
                    >
                        <dealer-chat
                            v-if="token"
                            ref="chat"
                            token-field="channel_client_token"
                            endpoint="/chats"
                            :user="userData.email"
                            :user-data="userData"
                            :branch-id="userData.default_company_branch"
                            :is-expanded.sync="isChatExpanded"
                            :show-header="true"
                            :show-channel-list="true"
                            :http-options="{ baseURL, headers: { Authorization: token }}"
                            :display-full="false"
                            :contacts="null"
                            :external-channel-handle="true"
                            @channel-clicked="handleChannelClick"
                        />
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-left" mode="out-in">
            <notification-center
                v-if="showNotifications && showNotificationCenter"
                @toggleNotifications="toggleNotifications"
            />
        </transition>
    </div>
</template>

<script>
const { AppHeader, AppSidebar, TwilioChat: DealerChat } = require(`./import.${process.env.VUE_APP_IMPORTS}`);
import { setAppTheme } from "@/utils/helpers";
import generalMixins from "@/mixins/general";
import { mapActions, mapGetters, mapState } from "vuex";
import BasicModal from "@/components/modals/basic-modal";
import SubscriptionBar from "@/views/layout/subscription-bar";
import FullscreenLoader from "@c/fullscreen-loader";
import NotificationCenter from "@/views/layout/notification-center";

export default {
    components: {
        AppHeader,
        AppSidebar,
        BasicModal,
        SubscriptionBar,
        FullscreenLoader,
        NotificationCenter,
        DealerChat
    },
    mixins: [
        generalMixins
    ],
    data() {
        return {
            domain: process.env.VUE_APP_DOMAIN || "",
            showSidebar: false,
            sidebarState: "hover",
            showNotificationCenter: false,
            baseURL: process.env.VUE_APP_BASE_API_URL,
            isChatExpanded: false
        };
    },
    computed: {
        ...mapState({
            appsList: state => state.Application.apps,
            appSettings: state => state.Application.settings,
            companyData: state => state.Company.data,
            companiesList: state => state.Company.list,
            notificationsCount: state => state.Notifications.notifications.total_notifications || 0,
            resources: state => state.Application.resources,
            userData: state => state.User.data,
            userPermissions: state => state.User.abilities,
            token: state => state.User.token
        }),
        ...mapGetters({
            companyBranchData: "Company/currentBranch",
            isSubscriptionBased: "Application/isSubscriptionBased",
            isChatActive: "Application/isChatActive",
            showSubscriptionBar: "Subscription/showSubscriptionBar",
            userIsLoggedIn: "User/isLoggedIn",
            featureFlags: "Application/featureFlags"
        }),
        showNotifications() {
            return this.appSettings.settings && Boolean(+this.appSettings.settings.show_notifications);
        },
        hideCompanySelector() {
            return this.appSettings.settings && Boolean(+this.appSettings.settings.hide_company_selector);
        },
        showChat() {
            return this.$route.meta && this.$route.meta.requiresAuth == undefined && this.token && this.isChatActive;
        },
        appLogo() {
            return this.appSettings.settings && this.appSettings.settings.logo || "";
        }
    },
    watch: {
        userPermissions() {
            this.$ability.update(this.userPermissions.rules);
        }
    },
    async created() {
        await this.$store.dispatch("Application/setEnv");
        await this.getAppData();
        this.sidebarState = this.appSettings.settings.default_sidebar_state;
        this.appInitialize();
    },
    mounted() {
        // Start a single listener for pusher events
        // to emit internally to packages and other areas of the app
        this.initPusher();
        window.addEventListener("resize", () => {
            if (screen.availWidth <= 991) {
                this.sidebarState = "closed";
            } else {
                this.sidebarState = this.appSettings.settings.default_sidebar_state;
            }
        });
        FB.init({
            appId : process.env.VUE_APP_FACEBOOK_APP_ID,
            xfbml : true,
            version : "v5.0"
        });
    },
    updated() {
        if (this.userData.is_multi_company) {
            document.querySelector(".multiselect__single").innerHTML = "All My Rooftops";
        }
        try {
            const dsb = document.querySelector("#sidebar-menu");
            (dsb.querySelector("a").hidden = true)
        } catch (error) {
            //
        }
    },
    beforeDestroy() {
        this.$pusher.unsubscribe("LEADS");
    },
    methods: {
        ...mapActions({
            getAppData: "Application/getSettings",
            setCompanyData: "Company/setData",
            setUserData: "User/setData"
        }),
        all(company) {
            window.$browseTable.$refs.gwBrowse.$refs.Vuetable.fireEvent("loading");
            document.querySelector(".multiselect__single").innerHTML = "All My Rooftops";
            axios({
                method: "GET",
                url: `/search/leads?sort=created_at%7Cdesc&page=1&format=true&text=`
            }).then(({ data: { data } }) => {
                const raw = [...data].filter(({ owner }) => owner?.id === this.userData.id);
                window.$browseTable.$refs.gwBrowse.$refs.Vuetable.fireEvent("loaded");
                window.$browseTable.$refs.gwBrowse.$refs.Vuetable.setData(raw);
            });
            this.switchCompany(company, true);
        },
        appInitialize() {
            setAppTheme(this.appSettings.settings);
        },
        handleSidebar(state) {
            this.showSidebar = state;
        },
        logout() {
            axios({
                method: "PUT",
                url: "/auth/logout"
            }).then(() => {
                const logoutUrl = this.appSettings.settings.logout_url || "";
                Cookies.remove("token", { path: "/", domain: this.domain });
                Cookies.remove("refresh-token", { path: "/", domain: this.domain });
                this.$store.dispatch("User/setToken", null);
                this.$store.dispatch("User/setRefreshToken", null);
                this.$store.dispatch("Application/resetGlobalData");

                if (logoutUrl) {
                    location.href = logoutUrl;
                } else {
                    this.$router.push({ name: "login" });
                }
            });
        },
        switchCompany(company, isMulti = false) {
            return axios({
                url: `/users/${this.userData.id}`,
                method: "PUT",
                data: {
                    "default_company": company.companies_id,
                    "default_company_branch": company.id,
                    "isMulti": Number(isMulti)
                }
            });
        },
        switchCompanyGuard(company) {
            const navigationGuard = this.$route.meta.navigationGuard;
            if (typeof navigationGuard !== "undefined" && navigationGuard.branchSwitch) {
                const resource = this.$route.params.resource;

                this.basicActionsModal({
                    title: "Navigation Notice",
                    message: `Changing to a branch which this ${resource} does not belong will redirect you to ${resource} List.`,
                    handler: () => {
                        this.switchCompany(company).then(() => {
                            const companyData = this.companiesList.find(item => item.id === company.companies_id);
                            const userData = {
                                ...this.userData,
                                default_company: company.companies_id,
                                default_company_branch: company.id
                            };

                            this.setCompanyData(companyData);
                            this.setUserData(userData);

                            this.$router.push({
                                name: "browse",
                                params: {
                                    resource
                                }
                            });
                        });
                    }
                });
            } else {
                this.switchCompany(company).then(() => {
                    window.location.reload();
                });
            }
        },
        toggleNotifications() {
            this.showNotificationCenter = !this.showNotificationCenter;
        },
        handleChannelClick(channel) {
            this.isChatExpanded = false;
            this.$router.push({
                name: "edit-resource",
                params: {
                    resource: "leads",
                    id: channel.uniqueName.replace("chat-", "")
                },
                query: {
                    tab: "chat"
                }
            })
        },
        initPusher() {
            const channel = this.$pusher.subscribe("LEADS");
            channel.bind("UPDATE", (data) => {
                this.$root.$emit("leads:stopwatch", data);
            });
        }
    }
}
</script>

<style lang="scss">
.app-header .company-switcher {
    .multiselect {
        &__content-wrapper {
            min-width: 160px;
        }

        &__element:first-child::before {
            align-items: center;
            background-color: rgb(244, 253, 254);
            border-bottom: 1px solid #E1E8ED;
            color: rgb(30, 208, 228);
            content: "Select Rooftops";
            display: flex;
            min-height: 32px;
            padding: 0 22px;
        }

        &__option {
            min-height: 32px;
            padding: 0 22px !important;

            &--group {
                display: none !important;
            }
        }
    }
}


.app {
    #page-sidebar {
        background-color: var(--base-color);

        .menu-category .menu-category-title {
            color: white;
        }
    }

    .page-sidebar {
        .sidebar-menu {
            .icon-thumbnail {
                img {
                    width: 100%;
                }
            }
        }
    }

    .page-container {
        width: 100%;
        height: 100%;
        padding-left: 70px;
        background-color: inherit;

        &.menu-pinned  {
            padding-left: 280px;
        }

        @media (max-width: $lg) {
            padding-left: 0;
            position: relative;
            transition: transform .25s ease;
        }

        .page-content-wrapper {
            min-height: 100%;
            position: relative;

            .content {
                padding-top: 90px;
                padding-bottom: 70px;
                min-height: 100%;
                transition: all .3s ease;

                .container-fluid {
                    padding-left: 30px;
                    padding-right: 30px;
                    position: relative;

                    @media (min-width: 1824px) {
                        width: 1700px;
                        margin-right: auto;
                        margin-left: auto;

                        &.menu-pinned {
                            width: 1450px;
                        }
                    }

                    @media (max-width: $md) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }
        }

        &.subscription-bar {
            .app-header {
                top: 50px;
            }

            .content {
                padding-top: 140px;
            }
        }
    }
}


body:not(.deslindate-app) {
    .app {
        .page-container {
            .page-content-wrapper {
                .content {
                    .container-fluid:not(.login-wrapper) {
                        padding-left: 30px;
                        padding-right: 30px;
                        position: relative;
                        margin-right: auto;
                        margin-left: auto;

                        @media (min-width: 1824px) {
                            max-width: 1700px;

                            &.menu-pinned {
                                max-width: 1450px;
                            }
                        }

                        @media (max-width: $md) {
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .app-header {
        .company-switcher {
            .multiselect__single {
                font-family: Inter, sans-serif;
            }
        }

        .app-switcher .app-mode {
            font-family: Inter, sans-serif;
        }

        .user-name {
            font-family: Inter, sans-serif;
        }
    }
}

.floating-chat {
    position: fixed;
    background: white;
    border: 1px solid #ccc;
    right: 20px;
    bottom: -1px;
    width: 350px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    z-index: 100;

    &.expanded {
        height: 70vh;
    }
}

html {
    height: 100%;
}
</style>
